import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, UntypedFormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../../../core/services/authentication.service";
import {OverlayService} from "@clavisco/overlay";
import {AlertsService, CLModalType, CLToastType, ModalService} from "@clavisco/alerts";
import {catchError, finalize, tap} from "rxjs";
import {BaseResponse} from "../../../models/responses";
import {GetError, Structures} from "@clavisco/core";
import {ExecutionType} from "../../../core/enums/enums";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Component({
  selector: 'app-verification-email',
  templateUrl: './verification-email.component.html',
  styleUrls: ['./verification-email.component.scss']
})
export class VerificationEmailComponent implements OnInit, AfterViewInit {

  verifyEmail: boolean = true; //variable para el mensaje de verificacion
  verificationForm!: FormGroup;
  verifyOwner!: boolean;
  token: any;

  //Validación para mostrar/ocultar contraseña
  hide: boolean = true;
  hideConfirmPass: boolean = true;

  constructor(private activatedRoute: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private router: Router,
              private overlayService: OverlayService,
              private alertService: AlertsService,
              private modalService: ModalService,
              private cd: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.authenticationService.logoutVerificationEmail();
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    const verificationType: any = this.activatedRoute.snapshot.paramMap.get('verificationType');
    if(verificationType?.toString() === '1'.toString()){
      this.verifyOwner = true;
      this.overlayService.OnPost('Verificando el correo, espere por favor...');
      this.authenticationService.ConfirmEmail(this.token).pipe(
        finalize(()=>this.overlayService.Drop())
      ).subscribe({
        next:(data:ICLResponse<BaseResponse>):void=>{
            this.alertService.Toast({message: 'Verificado con éxito.', type: CLToastType.SUCCESS, darkMode: true, horizontalPosition: "right", verticalPosition: "top"});
            this.verifyEmail = true;
            this.router.navigate(['/login']);
        }
      })
    }else{
      this.verifyOwner = false;
      this.verificationForm = this.CreateVerifyForm();
    }
  }

  CreateVerifyForm():FormGroup{
    return new FormGroup({
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required)
    });
  }

  OnSubmit(): void{
    if(this.verificationForm.valid){
      if(this.CheckPasswords(this.verificationForm)){
        this.overlayService.OnPost('Verificando el correo, espere por favor...');
        const pass:string =this.verificationForm.value.password
        this.authenticationService.ConfirmEmailOwnerAccount(this.token, pass).pipe(
          finalize(()=>this.overlayService.Drop())
        ).subscribe({
          next:(data:ICLResponse<BaseResponse>):void=>{
              this.alertService.Toast({message: 'Verificado con éxito.', type: CLToastType.SUCCESS, darkMode: true, horizontalPosition: "right", verticalPosition: "top"});
              this.verifyEmail = true;
              this.authenticationService.logout();
          },
          error: (err):void => {
          this.verifyEmail = false;
          }
        })
      }else{
        this.modalService.Open({
          type: CLModalType.WARNING,
          subtitle: '',
          disableClose: false,
          title: `Datos incorrectos, las contraseñas no coinciden.`,
          options: {
            ConfirmButton: {
              Action: Structures.Enums.CL_ACTIONS.CONTINUE,
              Color: 'primary',
              Title: 'Ok',
              Data: '',
              Icon: ''
            },
          }
        }).pipe(tap()).subscribe();
      }
    }
  }

  //metodo para validad que las contrasenias sean iguales al registrar un usuario nuevo
  CheckPasswords(group: UntypedFormGroup): boolean{
    const pass = group.controls['password'].value;
    const confirmPass = group.controls['confirmPassword'].value;
    return pass === confirmPass;
  }


  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  protected readonly ExecutionType = ExecutionType;
}
