import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {BaseResponse, GeneralConfigsResponse} from "../../models/responses";
import {GeneralConfigsModel} from "../interfaces/i-general-config";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable({
  providedIn: 'root'
})
export class GeneralConfigsService {
  private NameAction = new BehaviorSubject<string>('');
  Name = this.NameAction.asObservable();
  constructor(
    private http: HttpClient,
  ) {}

  // edita las configuraciones generales existentes en la DB
  EditGeneralConfigs(genConfigId: number, selectedFile: File) : Observable<ICLResponse<BaseResponse>>{
    const headers = new HttpHeaders({
      'Request-With-Files': 'true',
      'API': 'ApiAppUrl'
    });
    const fd: FormData = new FormData();

      fd.append('filePrintFormat', selectedFile);

    return this.http.patch<ICLResponse<BaseResponse>>(
      `api/GeneralConfigs?generalConfigsId=${genConfigId}`,
      fd,
      {headers}
    );
  }

  // obtiene las configuraciones generales existentes en la DB
  GetGeneralConfigs():Observable<ICLResponse<GeneralConfigsModel[]>> {

    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });

    return this.http.get<ICLResponse<GeneralConfigsModel[]>>(
      `api/GeneralConfigs/GetGeneralConfigs`,
      { headers }
    );
  }

  //Establece el nombre de la ventana activa en la barra de menú.
  SetNameAction(Action:string):void{
    this.NameAction.next(Action);
  }
}
