
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { GlobalFunctionsService } from "../../../core/services/global-functions.service";

import { Subscription } from 'rxjs';
import {BannerDialog} from "../../../core/interfaces/i-banner";

@Component({

  selector: 'app-banner-dialog',

  templateUrl: './banner-dialog.component.html',

  styleUrls: ['./banner-dialog.component.scss']

})

export class BannerDialogComponent implements OnInit, OnDestroy {

  imageDetails: string = '';

  escKeyPressSubscription!:Subscription;
  constructor(

    private dialogRef: MatDialogRef<BannerDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: BannerDialog,

    private globalFunction :GlobalFunctionsService

  ) { }

  ngOnInit(): void {

    this.setupEscKeyPressListener();

    this.imageDetails = this.data.Modal;

  }

  ngOnDestroy(): void {

    this.escKeyPressSubscription.unsubscribe();

  }

  setupEscKeyPressListener(): void {

    this.escKeyPressSubscription = this.globalFunction.escKeyPress().subscribe(() => {

      this.dialogRef.close(false);

    });

  }

  CloseBannerDialog(result: boolean):void{

    this.dialogRef.close(result);

  }

}
