import { Injectable } from '@angular/core';
import {PermissionsSelectedModel} from "../interfaces/i-perms";
import {Repository} from "@clavisco/core";
import {Token, TokenFE} from "../../models/FeCredentialsModel";
import {StorageBanner} from "../interfaces/i-banner";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private localStorageService;

  constructor(

  ) {
    this.localStorageService = localStorage;
  }
  GetCurrentSession():Token{
    return Repository.Behavior.GetStorageObject("currentUser") as Token;
  }

  GetCurrentFESession():TokenFE {
    const currentFESession = this.localStorageService.getItem('currentFEUser');
    return currentFESession !== null ? JSON.parse(currentFESession) : '';
  }

  SetCurrentFESession(currentUser: TokenFE): void {
    this.localStorageService.setItem('currentFEUser', JSON.stringify(currentUser));
  }
  // obtiene el token de FE de la sesion actual

  SetCurrentSession(currentUser: Token): void {
    Repository.Behavior.SetStorage(currentUser,'currentUser')
  }

  // remueve la sesion actual
  RemoveCurrentSession(): void {
    this.localStorageService.removeItem('currentUser');
  }

  // remueve el token de FE de la sesion actual
  RemoveCurrentFESession(): void {
    this.localStorageService.removeItem('currentFEUser');
  }

  SetSelectedCompany(
    companyName: string,
    companyId: number,
    codigoActividad: string,
    groupId: number,
    UseFactProv: boolean,
    SendReceptAndApInv: boolean
  ): void {
    const SelectedCompany = {
      companyName: companyName,
      companyId: companyId,
      codigoActividad: codigoActividad,
      groupId: groupId,
      UseFactProv: UseFactProv,
      SendReceptAndApInv: SendReceptAndApInv,
    };
    this.localStorageService.setItem('SelectedCompany', JSON.stringify(SelectedCompany));
  }

  // remueve la sesion actual
  RemoveSelectedCompany(): void {
    this.localStorageService.removeItem('SelectedCompany');
  }
  // obtiene la compania seleccionada

  GetSelectedCompany(): string {
    const SelectedCompany = this.localStorageService.getItem('SelectedCompany');
    return SelectedCompany !== null ? SelectedCompany : '';
  }

  GetItem<T>(itemName: string): T {
    const item = Repository.Behavior.GetStorageObject(itemName);
    return item! as T;
  }

  // remueve la comprobacion del primer logueo
  RemoveFirstLogin(): void {
    this.localStorageService.removeItem('firstLogin');
  }
  // obtiene la info de si es la primera vez que ingresa al home, deirecto del login
  GetFirstLogin(): string {
    return this.GetItem<string>("firstLogin");
  }

  //#region  Permisos
  SetPerms(perms: PermissionsSelectedModel[]):void {
    this.localStorageService.setItem('perms', JSON.stringify(perms));
  }

  GetPerms():PermissionsSelectedModel[] {
    return JSON.parse(this.localStorageService.getItem('perms')!);
  }

  RemovPerms(): void {
    this.localStorageService.removeItem('perms');
  }
  //#endregion

  SetBannerVisibilityByUser(
    currentUser:string,
    BannerVisibility:boolean,
    currentDate: string
  ):void{
    const bannerUserString = this.localStorageService.getItem('BannerUser');
    let bannerUserArray: StorageBanner[];
    const expirationDate = new Date(currentDate);
    expirationDate.setDate(expirationDate.getDate() + 1);
    if (bannerUserString) {
      bannerUserArray = JSON.parse(bannerUserString);

      // Verificar si el usuario ya existe en la lista
      const userIndex = bannerUserArray.findIndex((user: StorageBanner) => user.currentUser === currentUser);

      if (userIndex !== -1) {
        // Si el usuario ya existe, actualizar su visibilidad
        bannerUserArray[userIndex].BannerVisibility = BannerVisibility;
        if(new Date(bannerUserArray[userIndex].ExpiredDate) <= new Date(currentDate)){
          bannerUserArray[userIndex].CurrentDate = new Date(currentDate).toDateString();
          bannerUserArray[userIndex].ExpiredDate = expirationDate.toDateString();
        }
      } else {
        // Si el usuario no existe, agregarlo a la lista
        bannerUserArray.push({ currentUser: currentUser, BannerVisibility: BannerVisibility, CurrentDate: new Date(currentDate).toDateString(),ExpiredDate:expirationDate.toDateString()});
      }
    } else {
      // Si no hay datos almacenados, crear una nueva lista con el nuevo usuario
      bannerUserArray = [{ currentUser: currentUser, BannerVisibility: BannerVisibility , CurrentDate:new Date(currentDate).toDateString(),ExpiredDate:expirationDate.toDateString()}];
    }
    // Almacenar la lista actualizada en el localStorage
    this.localStorageService.setItem('BannerUser', JSON.stringify(bannerUserArray));
  }

  GetBannerVisibilityByUser(): string {
    // Obtener la lista de usuarios y sus configuraciones
    const BannerUser = this.localStorageService.getItem('BannerUser');
    // Devolver la visibilidad del banner del usuario actual si se encuentra
    return BannerUser !== null ? BannerUser : '';
  }
}
