import { RowColors } from "@clavisco/table";
import {ILineaDetalle} from "../core/interfaces/i-linea-base";

export interface DocumentoBase {

  // Código de la Actividad Económica
  CodigoActividad: string;
  // Condiciones de la venta: 01 Contado, 02 Crédito, 03 Consignación, 04 Apartado, 05 Arrendamiento con opción de compra, 06 Arrendamiento en función financiera, 99 Otros
  // coloque maxlength pero no dice, lo puse en dos porque el valor maximo en documentacion es 99
  CondicionVenta: string;
  // Plazo del crédito, es obligatorio cuando la venta del producto o prestación del servicio sea a crédito
  PlazoCredito: string;
  // Corresponde al medio de pago empleado: 01 Efectivo, 02 Tarjeta, 03 Cheque, 04 Transferencia - depósito bancario, 05 - Recaudado por terceros, 99 Otros
  // coloque maxlength pero no dice, lo puse en dos porque el valor maximo en documentacion es 99
  MedioPago: string;
}

export interface Documento extends DocumentoBase {
  Id: number;
  // Situación del comprobante electrónico 1 Normal, 2 Contongencia, 3 Sin Internet
  Situacion: number;
  // aqui se va a almacenar la fecha de creacion real de la factura, recibido en el api
  FechaFact: string | null;
  // se van a separaar los remitentes por punto y coma el primero va al campo de correo, los demas a este
  RcprCorreoElectronicoCC: string;
  // detalles de error
  ErrDetails: string;
  // compañia
  CompanyId: number;
  Sucursal: number;
  Terminal: number;
  // FE, NC y ND, por ahora tambien existe TE tiquete
  DocType: string;
  // //en caso de que el cliente requiera enviar algun otro numero de la factura, por ejemplo un id interno, en nuestro caso es para manejar DocEntry de SAP
  ConsecutivoId: string;
  // numero consecutivo factura sin configuracion de hacienda(numero factura)
  Consecutivo: string;
  // Emisor del documento
  // Nombre o razon social
  EmsrNombre: string;
  // Tipo de identificación: 01 Cédula Física, 02 Cédula Jurídica, 03 DIMEX, 04 NITE
  EmsrIdeTipo: string;
  // Número de identificación, el contribuyente debe estar inscrito ante la Administración Tributaria
  EmsrIdeNumero: string;
  // Nombre comercial
  EmsrNombreComercial: string;
  // Ubicacion Emisor EmsrUb
  EmsrUbProvincia: string;
  EmsrUbCanton: string;
  EmsrUbDistrito: string;
  EmsrUbBarrio: string;
  EmsrUbOtrasSenas: string;
  // Telefono Emisor EmsrTlf
  EmsrTlfCodigoPais: number;
  EmsrTlfNumTelefono: string;
  // fax Emisor EmsrFax
  EmsrFaxCodigoPais: number;
  EmsrFaxNumTelefono: string;
  EmsrCorreoElectronico: string;
  // Receptor
  // Nombre o razon social
  RcprNombre: string;
  // Tipo de identificación: 01 Cédula Física, 02 Cédula Jurídica, 03 DIMEX, 04 NITE
  RcprIdeTipo: string;
  // Número de identificación, el contribuyente debe estar inscrito ante la Administración Tributaria
  RcprIdeNumero: string;
  // Este campo será de condición obligatoria, cuando el cliente lo requiera
  RcprIdentificacionExtranjero: string;
  // Nombre comercial
  RcprNombreComercial: string;
  // Ubicacion Receptor RcprUb
  RcprUbProvincia: string;
  RcprUbCanton: string;
  RcprUbDistrito: string;
  RcprUbBarrio: string;
  RcprUbOtrasSenas: string;
  // Otras señas extranjero
  // Campo para incluir la dirección en el extranjero, en caso de requerirlo.
  RcprOtrasSenasExtranjero: string;
  // Telefono Receptor RcprTlf
  RcprTlfCodigoPais: number;
  RcprTlfNumTelefono: string;
  // fax Receptor RcprFax
  RcprFaxfCodigoPais: number;
  RcprFaxfNumTelefono: string;
  RcprCorreoElectronico: string;

  // Informacion de Referencia revisar si va en esta posicion para el armado xml
  InfRefTipoDoc: string;
  InfRefNumero: string;
  InfRefFechaEmision: string;
  InfRefCodigo: string;
  InfRefRazon: string;

  // RESUMEN DE FACTURA POR UN TEMA DE ORDEN EN EL XML SE COLOCO AQUI Y NO SE HEREDO DE RESUMEN DE FACTURA
  CodigoMoneda: string;
  TipoCambio: number;
  TotalServGravados: number;
  TotalServExentos: number;
  TotalServExonerado: number;

  TotalMercanciasGravadas: number;
  TotalMercanciasExentas: number;
  TotalMercExonerada: number;

  TotalGravado: number;
  TotalExento: number;
  TotalExonerado: number;

  TotalVenta: number;
  TotalDescuentos: number;
  TotalVentaNeta: number;
  TotalImpuesto: number;

  TotalIVADevuelto: number;
  TotalOtrosCargos: number;

  TotalComprobante: number;

  // observaciones, se adicionan a los valores otros si existen, sino se envian solo estos
  OtroTexto: string;

  OtTipoDocumento: string;
  OtNumeroIdentidadTercero: string;
  OtNombreTercero: string;
  OtDetalle: string;
  OtPorcentaje?: number;
  OtMontoCargo?: number;

  V_LineaDetalle: ILineaDetalle[];
}

export interface DocumentoWithUserId {
  documento: Documento;
  // id del usuario logueado
  UserId: string;
}

export interface DocumentModel {
  // Identificador del documento en la DBLocal
  Id: number;
  // Fecha de emision del documento
  FechaEmision: string;
  // Fecha de factura del documento
  FechaFact: string;
  // Nombre del receptor del documento
  RcprNombre: string;
  // Estado del documento
  Status: number;
  // Monto Total del documento
  TotalComprobante: number;
  // Tipo del documento
  DocType: string;
  // Consecutivo (DocNum de SAP)
  Consecutivo: string;
  // Clave de hacienda (Numero de factura)
  Clave: string;
  // Codigo de la Moneda del documento
  CodigoMoneda: string;
  // NumeroConsecutivo de la factura
  NumeroConsecutivo: string;
  // errores en documento
  ErrDetails: string;
  // cantidad maxima de items que se encontraron
  MaxQtyRowsFetch: number;
  // color de la fila
  RowColor: RowColors;
}


// modelo para obtener las cantidades de los documentos segun su estado
export interface DocumentsQuantitiesModel {
  // Estado del documento
  Status: number;
  // Cantidad de documentos en el estado indicado
  Quantity: number;
}

//#region BulkDownloadOfDocuments
export interface BulkDownloadOfDocumentsModel {

  /// Identificador de la tabla
  Id: number;

  /// Fecha de creacion de la peticion
  CreationDate: string;

  /// Id del usuario que ejecuto la accion
  UserId: string;

  /// Fecha inicial para la busqueda de documentos
  StartDate: string;

  /// Fecha final para la busqueda de documentos
  EndDate: string;

  /// Id de la company a la que se van a buscar ls documentos
  CompanyId: number;

  /// Tipo de documento a buscar(documento, recepcion), siempre aceptados en Hacienda
  DocType: number;

  /// Estado del envio(1 = En espera, 2 = Enviado, 3 = Error de envio)
  Status: number;

  /// Cantidad de intentos realizados para el envio
  AttemptsToSend: number;

  /// Fecha del ultimo intento de envio de los archivos
  LastAttempt: Date | null;

  /// Reconocer si utiliza la fecha de emision del XML o la fecha emision de doc (recepcion)
  UseXMLDate: boolean;

  /// Tipos de documentos a descargar (XML, PDF, XML DEL DOCUMENTO, ETC)
  KindOfDocuments: string;

  /// Correo para el destinatario
  ToEmail: string;

  /// Correo para las copias
  CCEmail: string;
}


// modelo para la tabla MensajeReceptor
export interface DocumentAcceptedModel {
  // Id del documento
  Id: number;
  // Detalle del mensaje
  DetalleMensaje: string;
  // Monto total impuesto
  MontoTotalImpuesto: number;
  // Monto total factura
  TotalFactura: number;
  // fecha emision Documento
  FechaEmisionDoc: string;
  // Fecha de emision del documento XML a recepcionar
  FechaEmisionXML: string;
  // Nombre emisor
  NombreEmisor: string;
  // Cedula emisor
  NumeroCedulaEmisor: string;
  // Consecutivo del emisor
  NumeroConsecutivoEmisor: string;
  // Estado del documento
  Status: number;
  // Estado interno del documento
  Mensaje: number;
  // Monto del impuesto a acreditar
  MontoTotalImpuestoAcreditar: number;
  // Monto del gasto aplicable
  MontoTotalDeGastoAplicable: number;
  // Reconocer si el tipo de doc 7 (MailParser) esta completo (true) o no (false)
  IsComplete: boolean;
  CondicionImpuesto: string;
  TaxFactor: number;
  CodigoActividad: string;
  // nuevo nombre del documento, con el que se guarda en el servidor
  DocName: string;
  // Almacena el docnum del documento creado en SAP
  ConsecutivoDoc: number;
  // SI POSEE UN PDF DE RECEPCION
  HavePathReceptPDF: boolean;
  // cantidad maxima de items que se encontraron
  MaxQtyRowsFetch: number;
  // Tipo del documento
  DocType: string;
  Bandeja:string;
  //Tipo de documento creado en sap(Borrador o factura)
  DocTypeSAP: string;
  //DocEntry del documento creado en sap
  DocEntry:string;
}

export interface ChangeStatusDocFE{
  // identificador del documento
  docId: number;
  //token de FE
  feToken: string;
}

// interface para listar las asignaciones en la vista
export interface Reception {
  Id?: number;
  // mensaje de recepcion
  Mensaje: number;
  // detaller del mensaje
  DetalleMensaje: string;
  // id de la sucursal
  Sucursal: number;
  // id del terminal
  Terminal: number;
  // id de la compañia
  CompanyId: number;
  CondicionImpuesto: string;
  TaxFactor: number;
  CodigoActividad: string;
  // id del usuario logueado
  UserId: string;
}
export interface ReceivingMessageFromMailParser {
  Recepcion: Reception;
  // si se edito la info o no
  editInfo: boolean;
}
export interface DocumentsAcceptedPaginatorModel extends DocumentAcceptedModel {
  // Estado del documento
  Status: number;
  // Cantidad de documentos en el estado indicado
  Quantity: number;
}

// modelo para los documentos por dias
export interface DocumentsPerDaysModel {
  GroupDate: Date;
  SumVal: number;
  InitialDate: Date;
}

// modelo para los documentos por semanas
export interface DocumentsPerWeekModel {
  SumVal: number;
  WeekNumber: number;
  InitialDate: Date;
  InitialWeekNumber: number;
}

// modelo para la tabla MensajeReceptor
export interface DocumentsPerMonthModel {
  SumVal: number;
  Mm: number;
  Yyyy: number;
}

export interface TopTenCustomer {
  CusName: string;
  SumVal: number;
}

export  interface DocumentsForChartModel
{
  Status: string;
  Quantity: number;
  Percent: number;
  CompanyId: number;
}

export interface DocumentPreview
{
  Reception: ReceptionModel | null;
  // INFORMACION DEL DOCUMENTO
  //Numeración consecutiva del comprobante
  NumeroConsecutivo: string;
  // public DateTime FechaEmision { get; set; }
  //se ajusta el string
  FechaEmision: string;
  // CLAVE DEL DOCUMENTO
  Clave: string;
  //Plazo del crédito, es obligatorio cuando la venta del producto o prestación del servicio sea a crédito
  PlazoCredito: string;
  // INFORMACION DEL EMISOR
  //Nombre o razon social
  EmsrNombre: string;
  //Nombre comercial
  EmsrNombreComercial: string;
  //Número de identificación, el contribuyente debe estar inscrito ante la Administración Tributaria
  EmsrNumero: string;
  // correo del emisor
  EmsrCorreoElectronico: string;
  // codigo del pais en telefono
  EmsrCodigoPaisTelefono: string;
  // numero de telefono
  EmsrNumTelefono: string;
  // codigo del pais en fax
  EmsrCodigoPaisFax: string;
  // numero de fax
  EmsrNumFax: string;
  // INFORMACION DEL RECEPTOR
  //Nombre o razon social
  RcprNombre: string;
  //Nombre comercial
  RcprNombreComercial: string;
  //Número de identificación, el contribuyente debe estar inscrito ante la Administración Tributaria
  RcprNumero: string;
  // correo del emisor
  RcprCorreoElectronico: string;
  // codigo del pais en telefono
  RcprCodigoPaisTelefono: string;
  // numero de telefono
  RcprNumTelefono: string;
  // codigo del pais en fax
  RcprCodigoPaisFax: string;
  // numero de fax
  RcprNumFax: string;
  // INFORMACION DEL DETALLE
  DetalleServicio: LineaDetallePreview[];
  //Informacion de cargos
  DetalleCargos: ChargesDetallePreview[];
  // RESUMEN DE LA FACTURA
  ResumenFactura: ResumenFacturaPreview | null;

}

export interface ReceptionModel
{
  Id: number;
  // mensaje de recepcion
  Mensaje: number;
  // detaller del mensaje
  DetalleMensaje: string;
  // id de la sucursal
  Sucursal: number;
  // id del terminal
  Terminal: number;
  // id de la compañia
  CompanyId: number;
  CodigoActividad: string;
  CondicionImpuesto: string;
  TaxFactor: number;

  BandejaReceptor: string;

}

export interface ChargesDetallePreview
{

  //Detalle de la mercancia transferida o servicio prestado
  Detalle: string;
  // precio del item o servicio
  PrecioUnitario: number;

}
export interface LineaDetallePreview
{
  // codigo del item o servicio
  Codigo: string;
  //Detalle de la mercancia transferida o servicio prestado
  Detalle: string;
  // cantidad del item o servicio
  Cantidad: number;
  // precio del item o servicio
  PrecioUnitario: number;
  // monto del descuento aplicado
  MontoDescuento: string;
  // subtotal
  SubTotal: number;
  //revisar, es decimal no string
  MontoTotalLinea: number;
}

export interface ResumenFacturaPreview
{
  // CODIGO DE LA MONEDA
  CodigoMoneda: string;
  // Es un número decimal compuesto por 13 enteros y 5 decimales
  TipoCambio: string;
  TotalServGravados: number;
  TotalServExentos: number;
  TotalServExonerado: number;
  TotalMercanciasGravadas: number;
  TotalMercanciasExentas: number;
  TotalMercExonerada: number;
  TotalGravado: number;
  TotalExento: number;
  TotalExonerado: number;
  TotalVenta: number;
  TotalDescuentos: number;
  TotalVentaNeta: number;
  TotalImpuesto: number;
  TotalIVADevuelto: number;
  TotalOtrosCargos: number;
  TotalComprobante: number;
}

export interface DocumentFile{
  //Documento en base 64
  File:string;
}


export interface Bandejas{
  //Correo electronico de los receptores
  BandejaReceptor:string;
}
