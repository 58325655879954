import { Injectable } from '@angular/core';
import {StorageService} from "./storage.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {
  AllCompaniesResponse,
  BaseResponse,
  MailParserConfigResponse
} from "../../models/responses";
import {MailParserConfigModel, MailParserpaginator} from "../interfaces/i-mailParserConfig";
import {Observable} from "rxjs";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {AllCompanyInfo} from "../../models/Company";

@Injectable({
  providedIn: 'root'
})
export class MailParserService {

  constructor(
    private http: HttpClient,
  ) { }

  GetMailParserPaginator(
    mailServer: string,
    email: string,
    companyName: string,
    status: number,// id de la compañía
    useToken: number,// id del grupo
    _pageNumber: number,
    _stepPos: number,
  ):Observable<ICLResponse<MailParserpaginator[]>> {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });

    const params = new HttpParams()
      .set('mailServer', mailServer)
      .set('mail', email)
      .set('status', status)
      .set('emsrNombre', companyName)
      .set('useToken', useToken)
      .set('startPost', _pageNumber)
      .set('stepPost', _stepPos);
    return this.http.get<ICLResponse<MailParserpaginator[]>>(
      `api/MailParser/GetMailParserByFilters`,
      {params, headers }
    );
  }

  CreateMailParser(mailparser:MailParserConfigModel):Observable<ICLResponse<BaseResponse>>  {
     const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    const parser = mailparser
    return this.http.post<ICLResponse<BaseResponse>>(
      `api/MailParser`,
      parser,
      { headers }
    );
  }

  EditMailParser(mailparser:MailParserConfigModel):Observable<ICLResponse<BaseResponse>>  {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    const parser = mailparser
    return this.http.patch<ICLResponse<BaseResponse>>(
      `api/MailParser`, parser,
      { headers }
    );
  }


  // metodo que obtiene las sucursales de la compañía seleccionada
  public GetAllCompanies():Observable<ICLResponse<AllCompanyInfo[]>>  {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<AllCompanyInfo[]>>(
      `api/MailParser/GetCompanies`,
      { headers }
    );
  }


}
