<div class="main">
  <div fxLayout="row wrap" fxLayoutAlign="space-between">
    <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50" fxFlex.gt-sm="50">
      <h2 mat-dialog-title><b>Detalle de cambios</b></h2>
    </div>
  </div>
  <div class="main">
    <div mat-dialog-content class="border main">
      <img  class="ImgDetails" [src]="imageDetails" >
    </div>
  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="flex-end" fxLayoutAlign.sm="space-between" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap.xs="3px" fxLayoutGap.sm="3px">
  <button type="button" mat-raised-button class="mt-btn" color="primary" (click)="CloseBannerDialog(true)" >
    <mat-icon>cancel</mat-icon>
    <span class="material-icons-outlined">Cerrar</span>
  </button>
</div>
