// modelo para la obtencion de las credenciales de fe de una compañía
export interface FeCredentialsModel {
  // Identificador de la credencial
  Id: number;
  // Identificador la compañia
  CompanyId: number;
  // Identificador del usuario
  UserId: string;
  // Constraseña
  Password: string;
}


export interface TokenFE{
  //token
  access_token: string;
  //fecha de expiracion
  expires_in:number;
  //tipo de token
  token_type:string;
  //hora de expiración del token en formato de cadena de caracteres.
  ExpireTime: string;
}

export interface Token{
  // token de acceso utilizado para realizar solicitudes autenticadas.
  "access_token": string;
  //tipo de token.
  "token_type": string;
  //duración en segundos antes de que el token expire.
  "expires_in": number;
  //nombre de usuario asociado al token.
  "UserName": string;
  //id del usuario asociado al token.
  "userId": string;
  //Indica si el usuario es propietario de la empresa.
  "isOwner": string;
  //id de la empresa asociada al token.
  "companyId": string;
  //nombre de la empresa asociada al token.
  "CompanyName": string;
  //hora de expiración del token en formato de cadena de caracteres.
  "ExpireTime": string;
  //código de actividad asociado al token.
  "CodigoActividad": string;
  //id del grupo asociado al token.
  "GroupId": string;
  //Indica si se utiliza el proveedor de facturación.
  "UseFactProv": string;
  //Indica si se envían receptores y facturas de proveedores.
  "SendReceptAndApInv": string;
  //fecha y hora en que se emitió el token en formato de cadena de caracteres.
  ".issued": string;
  //fecha y hora de expiración del token en formato de cadena de caracteres.
  ".expires": string;
}
