import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DocStatusRecept, DocTypes, MessageType, TaxCondition} from "../../../models/constants";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DocumentAcceptedModel} from "../../../models/Document";
import {AlertsService, CLModalType, CLToastType, ModalService} from "@clavisco/alerts";
import {OverlayService} from "@clavisco/overlay";
import {DocumentsService} from "../../../core/services/documents.service";
import {catchError, finalize, tap} from "rxjs";
import {GlobalFunctionsService} from "../../../core/services/global-functions.service";
import {BaseResponse} from "../../../models/responses";
import {GetError} from "@clavisco/core";

@Component({
  selector: 'app-content-recept-message',
  templateUrl: './content-recept-message.component.html',
  styleUrls: ['./content-recept-message.component.scss']
})
export class ContentReceptMessageComponent implements OnInit {
  receptForm:any;
  messageReceptList: any[]=[];
  condicionList: any[]=[];
  docRecept!:DocumentAcceptedModel;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertsService,
    @Inject('OverlayService') private overlayService: OverlayService,
    private documentService: DocumentsService,
    private modalService: ModalService,
    private globalfuntion :GlobalFunctionsService,) { }

  ngOnInit(): void {
    this.globalfuntion.escKeyPress().subscribe(() => {
      this.dialogRef.close(false);
    });
    this.messageReceptList=MessageType;
    this.condicionList=TaxCondition;
    this.receptForm= this.CreateSearchForm();
    this.EditSearchForm(this.data.Description)
    this.docRecept=this.data.Description;
  }
  CreateSearchForm():FormGroup {
    return new FormGroup({
      DocName: new FormControl({ value: '', disabled: true }),
      Mensaje: new FormControl('', Validators.required),
      CondicionImpuesto: new FormControl('',Validators.required),
      TaxFactor: new FormControl('0'),
      CodigoActividad: new FormControl('',[Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
      DetalleMensaje: new FormControl('',Validators.required),
    });
  }
  EditSearchForm(data:DocumentAcceptedModel):void {
    this.receptForm.patchValue({
      DocName: data?.DocName,
      Mensaje: data?.Mensaje,
      CondicionImpuesto: data?.CondicionImpuesto,
      TaxFactor: data?.TaxFactor,
      CodigoActividad: data?.CodigoActividad,
      DetalleMensaje: data?.DetalleMensaje,
    });
  }
  OnSubmit():void {
    if (this.receptForm.invalid) {
      this.alertService.Toast({ message: `El formulario contiene errores`, type: CLToastType.WARNING, darkMode:true,horizontalPosition:"right", verticalPosition:"top" })
      return;
    }
    const { Mensaje, DetalleMensaje, CondicionImpuesto, TaxFactor, CodigoActividad} = this.receptForm.value;
    this.docRecept.Mensaje = Mensaje;
    this.docRecept.DetalleMensaje = DetalleMensaje;
    this.docRecept.CondicionImpuesto = CondicionImpuesto;
    this.docRecept.TaxFactor = TaxFactor;
    this.docRecept.CodigoActividad = CodigoActividad;


    this.overlayService.OnPost('Enviando documento a aceptar, espere por favor...'); // Start blocking
    this.documentService.ReceptMessageFromMailParser(this.docRecept, true).pipe(
      finalize(() => this.overlayService.Drop())
    ).subscribe({
      next: (data:BaseResponse):void=>{
        if (data.result) {
          this.alertService.Toast({ message: `Enviado correctamente`, type: CLToastType.SUCCESS, darkMode:true,horizontalPosition:"right", verticalPosition:"top" })
          this.CloseModal(false);
        } else {
          this.alertService.Toast({ message: `${data.errorInfo.Message}`, type: CLToastType.WARNING, darkMode:true,horizontalPosition:"right", verticalPosition:"top" })
        }
      }
    })
  }

  CloseModal(b: boolean):void {
    this.dialogRef.close(b);
  }
}
