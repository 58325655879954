import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpStatusCode
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {CLModalType, ModalService} from '@clavisco/alerts';
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {AuthenticationService} from "../core/services/authentication.service";
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private modalService: ModalService,
    private sessionService: AuthenticationService,) {
  }

  //Mapping HTTP status codes to specific error handling methods
  private ErrorHandlers:{ [key: number]: (error: any) => void } = {
    [HttpStatusCode.Conflict]: (error: any) => this.HandleConflict(error),
    [HttpStatusCode.Unauthorized]: (error: any) => this.HandleUnauthorized(error),
    [HttpStatusCode.InternalServerError]: (error: any) => this.HandleInternalServerError(error),
    [HttpStatusCode.Forbidden]: (error: any) => this.HandleForbidden(error),
    '0': (error: any) => this.HandleNoConnection(error),
  };
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((error: any) => {
      const handler = this.ErrorHandlers[error['status']];
      if (handler) {
        handler(error);
      } else {
        this.HandleUnknownError(error);
      }
      return throwError(() => new Error(error.message || 'Unknown error'));
    }));
  }

  /**
   * Handles errors when there is no connection to the server.
   *
   * @param {any} error - The error object.
   *
   * This method displays a modal indicating that there is no connection to the server.
   */
  private HandleNoConnection(error: any): void {
    this.modalService.Continue({
      title: 'Sin conexión con el servidor',
      type: CLModalType.ERROR
    }).subscribe();
  }

  /**
   * Handles unauthorized errors (HTTP status 401).
   *
   * @param {any} error - The error object.
   *
   * This method checks if the error URL includes the API URL from the environment configuration.
   * If it does, it displays a modal indicating an invalid authorization token and logs out the user.
   * Otherwise, it handles specific unauthorized error messages.
   */
  private HandleUnauthorized(error: any): void {
    const notFoundMessages: string[] = ['Tenant not found', 'The username or password you entered is incorrect']; // Lista de opciones
    const apiUrl = environment.ApiAppUrl;
    const errorMessage = error?.error?.Message;
    const errorCode = error?.error?.Code;

    const modalType: CLModalType.ERROR | CLModalType.INFO =
      notFoundMessages.some(notFoundMessage => errorMessage.includes(notFoundMessage)) ? CLModalType.ERROR : CLModalType.INFO;

    if(error['url'].includes(apiUrl)){

      this.modalService.Continue({
        title: errorMessage,
        type: modalType
      }).subscribe();

      if (modalType === CLModalType.INFO) {
        this.sessionService.logout();
      }

    } else if (errorCode === HttpStatusCode.Unauthorized) {
      this.modalService.Continue({
        title: errorMessage,
        type: CLModalType.ERROR
      }).subscribe();
    }
  }

  /**
   * Handles conflict errors (HTTP status 409).
   *
   * @param {any} error - The error object.
   *
   * This method displays a modal with the error message indicating a conflict.
   */
  private HandleConflict(error: any): void {
    this.modalService.Continue({
      title: 'Error',
      subtitle: error['message'],
      type: CLModalType.ERROR
    }).subscribe();
  }

  /**
   * Handles internal server errors (HTTP status 500).
   *
   * @param {any} error - The error object.
   *
   * This method displays a modal with the error message indicating an internal server error.
   */
  private HandleInternalServerError(error: any): void {
    this.modalService.Continue({
      title: 'Error interno del servidor',
      subtitle: error['error']?.Message || error['error'],
      type: CLModalType.ERROR
    }).subscribe();
  }

  /**
   * Handles forbidden errors (HTTP status 403).
   *
   * @param {any} error - The error object.
   *
   * This method displays a modal indicating that the user does not have permission to perform the action.
   */
  private HandleForbidden(error: any): void {
    this.modalService.Continue({
      title: 'No tiene permisos para realizar esta acción',
      type: CLModalType.ERROR
    }).subscribe();
  }

  /**
   * Handles unknown errors.
   *
   * @param {any} error - The error object.
   *
   * This method extracts information from the error URL and displays a modal with a generic error message.
   */
  private HandleUnknownError(error: any): void {
    const errormensaje = error.url.split('/')
    this.modalService.Continue({
      title: 'Error en '+ errormensaje[4],
      subtitle:error['error']?.Message,
      type: CLModalType.ERROR
    }).subscribe();
  }
}
