import { NgModule } from '@angular/core';
import { Routes, RouterModule,  } from '@angular/router';
import {PagesComponent} from "./pages/pages.component";
import {AuthGuard} from "./core/guards/auth.guard"
import {VerifyPermissionsGuard} from "./core/guards/verify-permissions.guard";
import {VerificationEmailComponent} from "./pages/configuration/verification-email/verification-email.component";
import {EmailInboxModule} from "./pages/configuration/email-inbox/email-inbox.module";


/*import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';*/

export const APP_ROUTES: Routes = [

  {
    path: '',
    redirectTo: 'login', // Redirige la URL vacía a 'login'
    pathMatch: 'full'     // Asegura una coincidencia completa de la URL
  },
  {
    path: '',
    component: PagesComponent, children: [
      // { path: '', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
      { path: 'sign-in', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
      { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),canActivate: [AuthGuard] },
      { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),canActivate: [AuthGuard]  },


      //Documents
      { path: 'issued_documents', loadChildren: () => import('./pages/documents/documents/documents.module').then(m => m.DocumentsModule),canActivate: [VerifyPermissionsGuard]  },
      { path: 'accept_documents', loadChildren: () => import('./pages/documents/documents-accepted/documents-accepted.module').then(m => m.DocumentsAcceptedModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'accept_documents_gt', loadChildren: () => import('./pages/documents/documents-accepted-gt/documents-accepted-gt.module').then(m => m.DocumentsAcceptedGtModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'createAPInvoice/:docId/:docType/:IsFromEmailParse/:UseOthers', loadChildren: () => import('./pages/documents/create-apinvoice/create-apinvoice.module').then(m => m.CreateApinvoiceModule),canActivate: [AuthGuard] },
      { path: 'createDocument/:docType', loadChildren: () => import('./pages/documents/create-document/create-document.module').then(m => m.CreateDocumentModule), runGuardsAndResolvers: 'paramsChange',canActivate: [VerifyPermissionsGuard] },
      { path: 'reception_documents', loadChildren: () => import('./pages/documents/documents-reception/documents-reception.module').then(m => m.DocumentsReceptionModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'mailParser', loadChildren: () => import('./pages/documents/log/log.module').then(m => m.LogModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'email_report', loadChildren: () => import('./pages/documents/email-report/email-report.module').then(m => m.EmailReportModule),canActivate: [VerifyPermissionsGuard] },

      //Configurations
      { path: 'company', loadChildren: () => import('./pages/configuration/company/company.module').then(m => m.CompanyModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'CreateEditCompany/:idCompany/:grpId', loadChildren: () => import('./pages/configuration/company/create-or-update-company/create-or-update-company.module').then(m => m.CreateOrUpdateCompanyModule),canActivate: [AuthGuard] },
      { path: 'groups', loadChildren: () => import('./pages/configuration/groups/groups.module').then(m => m.GroupsModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'registerUsers', loadChildren: () => import('./pages/configuration/users/register-users/register-users.module').then(m => m.RegisterUsersModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'completeUsersRegistration', loadChildren: () => import('./pages/configuration/users/complete-users-registration/complete-users-registration.module').then(m => m.CompleteUsersRegistrationModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'udfs', loadChildren: () => import('./pages/configuration/udfs/udfs.module').then(m => m.UdfsModule),canActivate: [VerifyPermissionsGuard]},
      { path: 'sucursal', loadChildren: () => import('./pages/configuration/sucursal/sucursal.module').then(m => m.SucursalModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'mailParserConfig', loadChildren: () => import('./pages/configuration/mail-parser-config/mail-parser-config.module').then(m => m.MailParserConfigModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'numbering', loadChildren: () => import('./pages/configuration/numbering-config/numbering-config.module').then(m => m.NumberingConfigModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'userAssignment', loadChildren: () => import('./pages/configuration/users/user-assignment/user-assignment.module').then(m => m.UserAssignmentModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'permsByRol', loadChildren: () => import('./pages/configuration/perms/perms-by-rol/perms-by-rol.module').then(m => m.PermsByRolModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'Rol', loadChildren: () => import('./pages/configuration/rol/rol/rol.module').then(m => m.RolModule),canActivate: [VerifyPermissionsGuard]},
      { path: 'rolUserCompany', loadChildren: () => import('./pages/configuration/rol/rol-by-user-company/rol-by-user-company.module').then(m => m.RolByUserCompanyModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'updateUserInfo', loadChildren: () => import('./pages/configuration/users/update-user-info/update-user-info.module').then(m => m.UpdateUserInfoModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'emailInbox', loadChildren: () => import('./pages/configuration/email-inbox/email-inbox.module').then(m => m.EmailInboxModule),canActivate: [VerifyPermissionsGuard] },


      //Others
      { path: 'docReport', loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),canActivate: [VerifyPermissionsGuard] },
      { path: 'generalConfigs', loadChildren: () => import('./pages/general-configs/general-configs.module').then(m => m.GeneralConfigsModule),canActivate: [VerifyPermissionsGuard] },
    ]
  },

  {path: 'verificationEmail/:token/:verificationType', component: VerificationEmailComponent},

  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  {path: '**',loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),canActivate: [AuthGuard] },

];


export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES,{ useHash: true});


