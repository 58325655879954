<div class="modal-header">
  <h4 class="modal-title">Recepcionar el Documento</h4>
</div>
<div class="modal-body">
  <form [formGroup]="receptForm">
    <div fxLayout="row wrap">
      <div fxFlex="100" fxFlex.gt-sm="100" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Documento</mat-label>
          <input matInput formControlName="DocName">
        </mat-form-field>
      </div>
      <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Seleccione la respuesta:</mat-label>
          <mat-select formControlName="Mensaje">
            <mat-option *ngFor="let element of messageReceptList" [value]="element.Id">
              {{element.Name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="receptForm.controls['Mensaje'].errors?.['required']">
            La respuesta es requerido.
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Condición Impuesto:</mat-label>
          <mat-select formControlName="CondicionImpuesto">
            <mat-option *ngFor="let element of condicionList" [value]="element.Value">
              {{element.Description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="receptForm.controls['CondicionImpuesto'].errors?.['required']">
            La respuesta es requerido.
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Factor %:</mat-label>
          <input type="number" matInput formControlName="TaxFactor" [ngClass]="{'is-invalid': receptForm.get('TaxFactor').errors && receptForm.get('TaxFactor').touched}"/>
        </mat-form-field>
      </div>
      <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Código Actividad:</mat-label>
          <input matInput formControlName="CodigoActividad">
          <mat-error *ngIf="receptForm.controls['CodigoActividad'].errors?.['required']">
            El código de la actividad es requerido.
          </mat-error>
          <mat-error *ngIf="receptForm.controls['CodigoActividad'].errors?.['minlength']">
            El código de Actividad debe poseer mínimo 6 caracteres.
          </mat-error>
          <mat-error *ngIf="receptForm.controls['CodigoActividad'].errors?.['maxlength']">
            El código de Actividad debe poseer máximo 6 caracteres.
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="100" fxFlex.gt-sm="100" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Mensaje:</mat-label>
          <textarea matInput rows="10" cols="110" formControlName="DetalleMensaje"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="Actions" fxLayoutAlign="flex-end" fxLayoutAlign.sm="space-between" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap.xs="3px" fxLayoutGap.sm="3px">

    <button type="button" mat-raised-button class="mt-btn ml-2" color="primary" (click)="CloseModal(false)">
      <mat-icon>do_disturb_alt</mat-icon>
      <span class="material-icons-outlined">
        Cancelar
      </span>
    </button>
    <button type="submit" mat-raised-button class="mt-btn" color="primary"  (click)="OnSubmit()" [disabled]="!receptForm.valid">
      <mat-icon>done_outline</mat-icon>
      <span class="material-icons-outlined">
        Enviar
      </span>
    </button>
</div>

