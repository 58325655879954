<cl-menu [Id]="MenuId" [Logo]="Logo" [Title]="Title" [User]="User" [Nodes]="MenuNodes">
  <div class="exclude" style="width: 100%">
    <mat-toolbar class="Base " fxLayout="row wrap" fxLayoutAlign="space-between center" >
      <div fxLayoutAlign="none center" fxLayoutGap="20px">
          <button mat-icon-button (click)="ToggleMenu()">
            <mat-icon>menu</mat-icon>
          </button>
          <mat-label  class="toolbar-title" >{{txtaccion}}</mat-label>
      </div>
      <div fxLayoutAlign="none center" fxLayoutGap="5px">
        <mat-label class="toolbar-title">Compañía:</mat-label>
        <button type="button" mat-flat-button class="mt-btn toolbar-title" color="primary" class="w-50"
                (click)="OpenSelectCompanyDialog()">
            <span class="material-icons-outlined">
              {{txtcompany}}
            </span>
        </button>
      </div>

    </mat-toolbar>
    <router-outlet class="exclude"></router-outlet>
  </div>
</cl-menu>

