import {Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {finalize, map, Observable, of, startWith, Subscription, tap} from "rxjs";
import {FormControl} from "@angular/forms";
import {CompaniesService} from "../../../core/services/companies.service";
import {CompanyWithDefaultModel} from "../../../models/Company";
import {CompaniesResponse, FeCredentialsResponse, PermissionsSelectedResponse} from "../../../models/responses";
import {StorageService} from "../../../core/services/storage.service";
import {GlobalFunctionsService} from "../../../core/services/global-functions.service";
import {AuthenticationService} from "../../../core/services/authentication.service";
import {MatDialogRef} from "@angular/material/dialog";
import {PermsService} from "../../../core/services/perms.service";
import {Globals} from "../../../Globals";
import {OverlayService} from "@clavisco/overlay";
import {AlertsService, CLModalType, CLToastType, ModalService} from "@clavisco/alerts";
import {GetError, Structures} from "@clavisco/core";
import {FeCredentialsModel} from "../../../models/FeCredentialsModel";
import {ICompanyPaginator} from "../../../core/interfaces/i-company";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {PermissionsSelectedModel} from "../../../core/interfaces/i-perms";


@Component({
  selector: 'app-select-company-dialog',
  templateUrl: './select-company-dialog.component.html',
  styleUrls: ['./select-company-dialog.component.scss']
})
export class SelectCompanyDialogComponent implements OnInit, OnDestroy {

  SelectedCompany: any; // objeto con la compannia seleccionada

  myControl = new FormControl('');
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]> = new Observable<string[]>();

  companiesList: CompanyWithDefaultModel[] = []; // lista completa de las compannias
  companiesListTypeahead: string[] = []; // lista completa de las compannias

  currentCompanySubscription?: Subscription; // suscripcion para obtener la company actual

  @ViewChild('selectCompanyImput', { static: false, read: ElementRef }) selectCompanyImput!: ElementRef<HTMLInputElement>;

  @Output() valueChange = new EventEmitter(); // evento de retorno


  constructor(
    private companiesService: CompaniesService,
    private permsService: PermsService,
    private storage: StorageService,
    private auth: AuthenticationService,
    private globalFunctionsService: GlobalFunctionsService,
    private globals: Globals,
    public dialogRef: MatDialogRef<any>,
    private alertService: AlertsService,
    private modalService: ModalService,
  @Inject('OverlayService') private overlayService: OverlayService,
  ) {
    this.currentCompanySubscription = this.auth.currentCompany.subscribe(comp => {
      this.SelectedCompany = comp;
    });
  }

  ngOnDestroy(): void {
        this.currentCompanySubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.OnLoad();
  }

  OnLoad():void{



    this.GetCompanies();

    this.filteredOptions = of(this.companiesListTypeahead);

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._Filter(value || '')),
    );
  }

  private _Filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    this.options = this.companiesListTypeahead;


    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  // funcion para selecionar un cliente y buscar su informacion en la DB
  SelectedCustomer():void {

    console.log(this.myControl.value);
    let item = this.myControl.value?.toString();
    // limpiar la lista de amortizacion
    let info = item?.split(' - ');
    let comp: CompanyWithDefaultModel[] = this.companiesList.filter((comp: CompanyWithDefaultModel) => {
      if(info && info.length > 0){
        return comp.EmsrIdeNumero === info[0];
      }
      else return null;

    });
    if(comp){
      this.ChangeCompany(comp[0].Id, comp[0].EmsrNombreComercial, comp[0].CodigoActividad, comp[0].GroupId, comp[0].UseFactProv,comp[0].SendReceptAndApInv);
    }else{
      this.CloseModal(true)
    }


  }

  ChangeCompany(companyId: number, ComercialName: string, CodigoActividad: string, groupId: number, UseFactProv: boolean,SendReceptAndApInv:boolean):void {
    this.overlayService.OnPost()
    this.storage.SetSelectedCompany(ComercialName, companyId, CodigoActividad, groupId, UseFactProv,SendReceptAndApInv);
    const SelectedCompany = {
      'companyName': ComercialName,
      'companyId': companyId,
      'codigoActividad': CodigoActividad,
      'groupId': groupId,
      'UseFactProv': UseFactProv,
      'SendReceptAndApInv':SendReceptAndApInv
    };
    this.auth.SetCurrentCompanyValue(SelectedCompany);
    this.SelectedCompany.companyName = ComercialName;
    this.SelectedCompany.companyId = companyId;
    this.SelectedCompany.codigoActividad = CodigoActividad;
    this.SelectedCompany.UseFactProv = UseFactProv;

    this.GetPermsByUser();

    const sessionObject = this.storage.GetCurrentSession();

    this.auth.GetFeCredentials(companyId, sessionObject.access_token).pipe(
      finalize(()=>this.overlayService.Drop())
    ).subscribe({
      next:(data:any):void=>{
        if(data){
          this.auth.removeFESesion();
          this.auth.getFEToken(data.Data[0]).subscribe({
            next:(dataFE):void=>{
              console.log(dataFE);
              this.CloseModal(true)
            }
          })
        }else{
          this.alertService.Toast({
            message: `${data.Message}`,
            type: CLToastType.WARNING,
            darkMode: true,
            horizontalPosition: "right",
            verticalPosition: "top"
          })
        }
      }
    })
    this.valueChange.emit(null);
  }


  // cambia los permisos en las diferentes tablas, ya sea si estan habilitados o deshabilitados
  GetPermsByUser():void {

    this.permsService.GetPermsByUser(this.SelectedCompany.companyId).subscribe({
      next:(data:ICLResponse<PermissionsSelectedModel[]>):void=>{
        if (data.Data.length) {
          this.storage.RemovPerms();
          this.storage.SetPerms(data.Data);
          this.auth.setCurrentPermsValue(data.Data);
          //this.close();
        } else {
          //this.alertService.warningAlert(`${data.errorInfo.Message}`);
        }
      },
      error:(err)=>{
        this.globals.permList.length = 0;
        this.globals.viewParamListMenu.length = 0;
        this.globals.viewParamListSubMenu.length = 0;
      }
    })
  }




  GetCompanies():void {
    this.overlayService.OnGet('Obteniendo compañias!!')
    this.companiesList.length = 0;
    this.companiesService.GetCompanies('','','').pipe(
      finalize(()=>this.overlayService.Drop())
    ).subscribe({
      next:(data:ICLResponse<ICompanyPaginator[]>):void=>{
        if (data.Data.length) {
          this.companiesList = data.Data;
          console.log("LoadCompanies",this.companiesList)
          this.companiesListTypeahead = this.companiesList.map((comp: CompanyWithDefaultModel) => {
            return `${comp.EmsrIdeNumero} - ${comp.EmsrNombreComercial}`;
          });
          // this.alertService.successInfoAlert(`Compañías obtenidas con éxito!!!`);
          let firstLogin = this.storage.GetFirstLogin();
          if (firstLogin && firstLogin === '1') {
            this.storage.RemoveFirstLogin();
          }
        } else {
          this.modalService.Continue({
            subtitle: `${data.Message}`,
            type: CLModalType.ERROR
          });
        }
      }

    })
  }

  CloseModal(result: boolean): void {
    this.dialogRef.close(result);
  }
}
