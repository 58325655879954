<div class="main">
  <h1 mat-dialog-title>Seleccione una compañía</h1>
  <div mat-dialog-content>
    <form class="example-form">
      <mat-form-field class="mt-btn w-100 mt-2" appearance="outline">
        <mat-label>Compañia</mat-label>
        <input type="text"
               placeholder="Digite el nombre de una compañia"
               aria-label="Number"
               matInput
               [formControl]="myControl"
               [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption id="selectCompanyImput" #auto="matAutocomplete" class="mt-btn w-100">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions fxLayoutAlign="flex-end" fxLayoutAlign.sm="space-between" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap.xs="3px" fxLayoutGap.sm="3px">
    <button type="button" mat-raised-button class="mt-btn ml-2" color="warn" (click)="CloseModal(false)">
      <mat-icon>cancel</mat-icon>
      <span class="material-icons-outlined">Cancelar</span>
    </button>
    <button type="button" mat-raised-button class="mt-btn " color="primary"  (click)="SelectedCustomer()" >
      <mat-icon>check</mat-icon>
      <span class="material-icons-outlined">Continuar</span>
    </button>

  </div>

</div>
