import {Inject, Injectable} from '@angular/core';
import {Search, SearchAcceptDoc, SearchStatusDoc, SearchReport, SearchAcceptDocGT} from "../../models/search";
import {StorageService} from "./storage.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {
  BaseResponse,
  DocumentAcceptedResponse,
  DocumentResponse,
  OutgoingMailsResponse,
  DownloadPDFFileResponse,
  IResponse,
  SuppliersResponse,
  GetDocsInfoSAPResponse,
  GetDocAPInvoiceInfoXMLResponse,
  DocTypeBaseResponse,
  ItemListResponse,
  WarehouseListResponse,
  TaxesResponse,
  ReceptAndApInvResponse,
  CreateAPInvoiceResponse,
  GetDocAPInvoiceChargesXMLResponse,
  CurrencyCodeDEResponse,
  CreateDocResponse,
  DocumentsPerDayResponse,
  DocumentsPerWeekResponse,
  DocumentsPerMonthResponse,
  TopTencustomerResponse,
  EmailsForChartResponse,
  DocumentsForChartResponse,
  DocumentXMLResponse, PreviewResponse, PreviewResponseForXML, ViewAcepXMLResponse, MatchAutomaticResponse

} from "../../models/responses";
import {
  Bandejas,
  BulkDownloadOfDocumentsModel,
  ChangeStatusDocFE,
  DocumentAcceptedModel, DocumentFile,
  Documento,
  DocumentoWithUserId, DocumentPreview,
  DocumentsAcceptedPaginatorModel, DocumentsForChartModel,
  DocumentsPerDaysModel, DocumentsPerMonthModel,
  DocumentsPerWeekModel,
  ReceivingMessageFromMailParser,
  Reception, TopTenCustomer
} from "../../models/Document";
import {BehaviorSubject, catchError, EMPTY, Observable, of, OperatorFunction} from "rxjs";
import {
  APInvoiceInfo,
  ChargesAPInvoiceBase, ChargesReceptXMLInfo,
  CreateReceptAndApInv, DocReceptXMLInfo, DocSAPInfoLines, DocTypeBaseModel, DocumentAPInvoice,
  DocumentAPInvoiceLines,
  GetDocBaseLines, UpdateConsecutivoDoc
} from "../../models/ProviderDocument";
import {IMappedUdfs} from "../interfaces/i-udf";
import {FormGroup, UntypedFormGroup} from "@angular/forms";
import {MatchAutomaticModel} from "../interfaces/i-match-automatic";
import {ILineaDetalle} from "../interfaces/i-linea-base";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {DatePipe} from "@angular/common";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {Supplier} from "../../models/supplier";
import {EmailsForChartModel, OutgoingMailDetails, OutGoingMails} from "../../models/Email";
import {Item} from "../interfaces/i-item";
import {CurrencyCode} from "../interfaces/i-CurrencyCode";
import {Warehouse} from "../interfaces/i-warehouse";
import {ITax} from "../interfaces/i-tax";
import {GlobalFunctionsService} from "./global-functions.service";


@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private alertService: AlertsService,
    private datePipe: DatePipe,
    private global: GlobalFunctionsService
  ) { }

  CreateReceptAndApInv(DATA_TO_SAVE: CreateReceptAndApInv):Observable<ICLResponse<ReceptAndApInvResponse>>  {
    const URL = `api/Documents/CreateAPInvoiceAndRecept`;
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });

    return this.http.post<ICLResponse<ReceptAndApInvResponse>>(URL, DATA_TO_SAVE, {
      headers,
    });
  }
  // obtiene los documentos
  GetDocuments(docSearch: Search):Observable<ICLResponse<DocumentResponse>>  {


    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    const params = new HttpParams()
      .set('StartDate', docSearch.StartDate)
      .set('EndDate',docSearch.EndDate)
      .set('DoctType',docSearch.DocType)
      .set('Status',docSearch.Status)
      .set('CompanyId',docSearch.CompanyId)
      .set('Consecutivo',docSearch.Consecutivo)
      .set('ConsecutivoFE',docSearch.ConsecutivoFE)
      .set('Receptor',docSearch.Receptor)
      .set('Cedula',docSearch.Cedula)
      .set('Clave',docSearch.Clave)
      .set('CodigoMoneda',docSearch.CodigoMoneda)
      .set('StartPost',docSearch.StartPos)
      .set('StepPost',docSearch.StepPos)

      .set('CodigoMoneda',docSearch.CodigoMoneda)

    return this.http.get<ICLResponse<DocumentResponse>>(
      `api/Documents`,
      {params, headers }
    );
  }

  GetBandejas(Idcompany: number): Observable<ICLResponse<Bandejas[]>>{

    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });

    return this.http.get<ICLResponse<Bandejas[]>>(
      `api/Documents/GetBandejasReceptores?CompanyId=${Idcompany}`,
      { headers }
    );
  }
  ViewDocument(Id: number):Observable<ICLResponse<string>>  {
    const URL = `api/Report/PrintInvoicePDF?id=${Id}`;
    const headers = new HttpHeaders({
      responseType: 'blob',
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<string>>(URL, { headers });
  }


  DownloadDocument(Id: number):Observable<ICLResponse<string>>  {
    const URL = `api/Report/DownloadInvoicePDF?id=${Id}`;
    const headers = new HttpHeaders({
      responseType: 'blob',
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<string>>(URL, { headers });
  }

  // funcion para la visualizacion del archivo xml
  // recibe como parametro el id del documento a visualizar
  ViewXML(Id: number):Observable<ICLResponse<ViewAcepXMLResponse>>  {
    const URL = `api/Documents/PrintDocumentXML?docId=${Id}`;
    const headers = new HttpHeaders({
      responseType: 'blob',
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<ViewAcepXMLResponse>>(URL, { headers });
  }

  // funcion para la descargar del archivo xml
  // recibe como parametro el id del documento a visualizar
  public DownloadXML(Id: number):Observable<ICLResponse<ViewAcepXMLResponse>>  {
    const URL = `api/Documents/DownloadDocumentXML?docId=${Id}`;
    const headers = new HttpHeaders({
      responseType: 'blob',
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<ViewAcepXMLResponse>>(URL, { headers });
  }

  // esta funcion se cambio para descargar no un archivo del server, sino un xml desde un b64
  // #REV# no eliminar
  GetXMLDoc(id: number):Observable<ICLResponse<ViewAcepXMLResponse>>  {

    const URL = `api/Documents/GetXMLDoc?docId=${id}`;
    const headers = new HttpHeaders({
      responseType: 'blob',
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<ViewAcepXMLResponse>>(URL, { headers });
  }

  // esta funcion se cambio para descargar no un archivo del server, sino un xml desde un b64
  // #REV# no eliminar
  GetOutgoingMails(id: number):Observable<ICLResponse<OutGoingMails[]>>  {
    const URL = `api/Email/GetOutgoingMails?docId=${id}`;
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<OutGoingMails[]>>(URL, { headers });
  }


  // funcion para el reenvio de una factura
  // recibe como parametro el id del documento a reenviar
  ResendEmailDocumentService(
    Id: number,
    OtherEmails: boolean,
    MailTo: string,
    MailCC: string
  ):Observable<ICLResponse<BaseResponse>>  {
    const Info = {
      DocId: Id,
      OtherEmails: OtherEmails,
      MailTo: MailTo,
      MailCC: MailCC,
    };
    const URL = `api/Email/`;
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.post<ICLResponse<BaseResponse>>(URL, Info, { headers });
  }


  BulkDownloadOfDocuments(
    request: BulkDownloadOfDocumentsModel
  ): Observable<ICLResponse<BaseResponse>> {
    const URL = `api/Report/BulkDownloadOfDocuments/`;
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });

    return this.http.post<ICLResponse<BaseResponse>>(URL,request,{ headers });
  }

  GetDocumentsAccepted(docSearch: SearchAcceptDoc):Observable<ICLResponse<DocumentAcceptedResponse>>  {

    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    const params = new HttpParams()
      .set('StartDate', docSearch.StartDate)
      .set('EndDate',docSearch.EndDate)
      .set('DocType',docSearch.DocType)
      .set('Status',docSearch.Status)
      .set('MessageType',docSearch.MessageType)
      .set('CompanyId',docSearch.CompanyId)
      .set('Consecutivo',docSearch.Consecutivo)
      .set('NombreEmisor',docSearch.NombreEmisor)
      .set('Clave',docSearch.Clave)
      .set('ConsecutivoEmisor',docSearch.ConsecutivoEmisor)
      .set('useXMLDates',docSearch.useXMLDates)
      .set('StartPost',docSearch.StartPos)
      .set('StepPost',docSearch.StepPos)
      .set('Bandeja',docSearch.Bandeja)
      .set('Cedula',docSearch.Cedula)
      .set('CodigoMoneda',docSearch.CodigoMoneda)


    return this.http.get<ICLResponse<DocumentAcceptedResponse>>(
      `api/Documents/SearchDocumentsAccepted`, { params, headers }
    );
  }


  /**
   * Metodo GetDocumentsAcceptedGT
   *
   * Este metodo envia una solicitud POST al servidor para obtener los documentos aceptados
   * basados en los parametros de busqueda proporcionados.
   *
   * @param docSearch - Objeto de tipo SearchAcceptDocGT que contiene los parametros de busqueda.
   * @returns Observable de tipo DocumentAcceptedResponse que contiene la respuesta del servidor.
   */
  GetDocumentsAcceptedGT(docSearch: SearchAcceptDocGT):Observable<ICLResponse<DocumentAcceptedResponse>>  {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    const params = new HttpParams()
      .set('StartDate', docSearch.StartDate)
      .set('EndDate',docSearch.EndDate)
      .set('DocType',docSearch.DocType)
      .set('Status',docSearch.Status)
      .set('MessageType',docSearch.MessageType)
      .set('CompanyId',docSearch.CompanyId)
      .set('Consecutivo',docSearch.Consecutivo)
      .set('NombreEmisor',docSearch.NombreEmisor)
      .set('Clave',docSearch.Clave)
      .set('ConsecutivoEmisor',docSearch.ConsecutivoEmisor)
      .set('useXMLDates',docSearch.useXMLDates)
      .set('StartPost',docSearch.StartPos)
      .set('StepPost',docSearch.StepPos)
      .set('Bandeja',docSearch.Bandeja)
      .set('Cedula',docSearch.Cedula)
      .set('CodigoMoneda',docSearch.CodigoMoneda)
      .set('DefaultOCTypes',docSearch.defaultOCTypes)
    return this.http.get<ICLResponse<DocumentAcceptedResponse>>(
      `api/Documents/SearchDocumentsAccepted/`,{ params, headers }
    );
  }

  DownloadPDF(docId: number):Observable<ICLResponse<DocumentFile>> {
    const URL = `api/Documents/DownloadPDF?docId=${docId}`;
    const headers = new HttpHeaders({
      responseType: 'blob',
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<DocumentFile>>(URL, { headers });
  }

  // funcion para la visualizacion del archivo xml
  // recibe como parametro el id del documento a visualizar
  ViewXMLAccepted(Id: number):Observable<ICLResponse<ViewAcepXMLResponse>>  {
    const URL = `api/Documents/GetDocumentXMLAccepted?docId=${Id}`;
    const headers = new HttpHeaders({
      responseType: 'blob',
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<ViewAcepXMLResponse>>(URL, { headers });
  }


  // metodo para cambiar el estado de un documento de 'error' a 'reprocesar'
  SetDocStatusReprocess(docId: number): Observable<ICLResponse<BaseResponse>> {
   const TOKENFE = this.storage.GetCurrentFESession();

    let changeDocStatus: ChangeStatusDocFE = {
      docId: docId,
      feToken: TOKENFE.access_token,
    };
    const URL = `api/Documents`;
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });

    return this.http.patch<ICLResponse<BaseResponse>>(URL, changeDocStatus, { headers });
  }

  ReceptMessageFromMailParser(doc: DocumentAcceptedModel, editInfo: boolean):Observable<DownloadPDFFileResponse>  {
    const APPSESSION = this.storage.GetCurrentSession();

    let Recepcion: Reception = {
      Id: doc.Id,
      Mensaje: doc.Mensaje,
      DetalleMensaje: doc.DetalleMensaje,
      Sucursal: 1,
      Terminal: 1,
      CompanyId: 0,
      CondicionImpuesto: doc.CondicionImpuesto,
      TaxFactor: doc.TaxFactor,
      CodigoActividad: doc.CodigoActividad,
      UserId: APPSESSION.userId,
    };

    let ReceivingMessageFromMailParser: ReceivingMessageFromMailParser = {
      Recepcion: Recepcion,
      editInfo: editInfo,
    };
    const TOKEN = this.storage.GetCurrentFESession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API': 'ApiFEUrl'
    });
    return this.http.post<DownloadPDFFileResponse>(
      `api/Documents/ReceptMessageFromMailParser/`,
      ReceivingMessageFromMailParser,
      { headers }
    );
  }

  GetDocumentInfoToPreview(docSearch: number):Observable<ICLResponse<DocumentPreview>>  {

    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<DocumentPreview>>(
      `api/Documents/GetDocumentInfoPreview?documentId=${docSearch}`,
      { headers }
    );
  }

  // obtiene lista de proveedores
  GetSuppliers(compId: number):Observable<ICLResponse<Supplier[]>>  {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    const URL = `api/BusinessPartners?companyId=${compId}`;
    return this.http.get<ICLResponse<Supplier[]>>(URL, { headers });
  }

  // obtiene las lineas del docBase consultado en SAP
  // tslint:disable-next-line: no-shadowed-variable
  GetDocBaseLines(GetDocBaseLines: GetDocBaseLines):Observable<ICLResponse<DocSAPInfoLines[]>>  {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    const params = new HttpParams()
      .set('tipoDocBase', GetDocBaseLines.TipoDocBase)
      .set('numDocBase',GetDocBaseLines.numDocBaseList[0])
      .set('companyId',GetDocBaseLines.compId)
    return this.http.get<ICLResponse<DocSAPInfoLines[]>>(
      `api/Documents/GetDocBaseLines`,
      {params, headers }
    );
  }

  //#region Manejo seccion creacion facturas proveedor (APInvoice)
  //#region obtiene los documentos
  GetDocAPInvoiceInfoXML(docId: number):Observable<ICLResponse<DocReceptXMLInfo[]>>  {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    const URL = `api/Documents/GetDocAPInvoiceInfoXML?docId=${docId}`;
    return this.http.get<ICLResponse<DocReceptXMLInfo[]>>(URL, { headers });
  }

  GetDocAPInvoiceChargesXML(docId: number):Observable<ICLResponse<ChargesReceptXMLInfo[]>>  {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    const URL = `api/Documents/GetDocAPInvoiceCharges?docId=${docId}`;
    return this.http.get<ICLResponse<ChargesReceptXMLInfo[]>>(URL, { headers });
  }

  //#endregion
  GetDocTypeBase(companyId: number):Observable<ICLResponse<DocTypeBaseModel[]>>  {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    const URL = `api/Documents/GetDocTypeBase?companyId=${companyId}`;
    return this.http.get<ICLResponse<DocTypeBaseModel[]>>(URL, { headers });
  }

  // obtiene lista de items
  GetItemList(compId: number):Observable<ICLResponse<Item[]>>  {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    const URL = `api/Item/GetItems?companyId=${compId}`;
    return this.http.get<ICLResponse<Item[]>>(URL, { headers });
  }

  // obtiene lista de almacenes
  GetWarehouseList(compId: number):Observable<ICLResponse<Warehouse[]>>  {
   const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    const URL = `api/Warehouse?CompanyId=${compId}`;
    return this.http.get<ICLResponse<Warehouse[]>>(URL, { headers });
  }

  // obtiene los codigos de impuesto desde SAP
  GetTaxCode(compId: number):Observable<ICLResponse<ITax[]>> {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    const URL = `api/Tax?CompanyId=${compId}`;
    return this.http.get<ICLResponse<ITax[]>>(URL, { headers });
  }
  //Funcion para agregar las lineas automaticas
  CheckMachtAutomatic(matchAutomatic: MatchAutomaticModel):Observable<ICLResponse<MatchAutomaticModel>> {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });

    // Envía el objeto matchAutomatic en el cuerpo de la solicitud
    return this.http.post<ICLResponse<MatchAutomaticModel>>(
      `api/Documents/MatchAutomatic`,
      matchAutomatic, // Envía el objeto en el cuerpo de la solicitud
      { headers }
    );

  }

  // envia los datos de la factura para crearla en SAP
  CreateAPInvoice(
    mappedUdfs: IMappedUdfs[],
    documentForm: FormGroup,
    TipoDocBase: number,
    DocBaseList: number[],
    APInvoiceLines: DocumentAPInvoiceLines[],
    DocumentAdditionalExpenses: ChargesAPInvoiceBase[],
    CompanyId: number,
    XmlId: number,
    selectedFileXML: File,
    selectedFilePDF: File,
    BandejaReceptor: string,
    _isSunvesta: boolean,
    isDraft: boolean,
  ) :Observable<ICLResponse<CreateAPInvoiceResponse>>{
    const TOKENFE = this.storage.GetCurrentFESession();
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });

    let CardCode: string;
    let CardName: string;
    let NumAtCard: string;
    let DocCur: string;
    let DocDate: Date;
    let DocDueDate: Date;
    let TaxDate: Date;
    let Comments: string;

    // se cargan los datos del formulario
    CardCode = documentForm.controls['CardCode'].value.split(' - ', 1)[0];
    CardName = documentForm.controls['CardName'].value;
    DocCur = documentForm.controls['DocCur'].value;
    NumAtCard = documentForm.controls['NumAtCard'].value;
    DocDate = documentForm.controls['DocDate'].value;
    DocDueDate = documentForm.controls['DocDueDate'].value;
    TaxDate = documentForm.controls['TaxDate'].value;
    Comments = documentForm.controls['Comments'].value;

    let documentAPInvoice: DocumentAPInvoice = {
      CardCode: CardCode,
      CardName: CardName,
      DocCur: DocCur,
      NumAtCard: NumAtCard,
      DocDate: DocDate,
      DocDueDate: DocDueDate ? DocDueDate : null,
      TaxDate: TaxDate,
      Comments: Comments,
      TipoDocBase: TipoDocBase,
      DocBaseList: DocBaseList,
      APInvoiceLines: APInvoiceLines,
      TotalFactura: 0,
      SupplierGetModel: '',
      LicTradNum: '',
      U_APEmail_FE: BandejaReceptor,
      MappedUdfs: mappedUdfs,
      DocumentAdditionalExpenses:DocumentAdditionalExpenses,
      DocObjectCode: isDraft?'oPurchaseInvoices': ''
    };
    let UpdateConsecutivoDoc: UpdateConsecutivoDoc = {
      docId: XmlId,
      feToken: TOKENFE.access_token,
      docnum: 0,
    };
    let APInvoiceInfo: APInvoiceInfo = {
      APInvoice: documentAPInvoice,
      CompanyId: CompanyId,
      UpdateConsecutivoDoc: UpdateConsecutivoDoc,

    };
    return this.http.post<ICLResponse<CreateAPInvoiceResponse>>(
      `api/Documents/CreateAPInvoice`,
      APInvoiceInfo,
      { headers }
    );
  }

  GetCurrencyCodeAD(companyId: number):Observable<ICLResponse<CurrencyCode[]>> {

    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });

    return this.http.get<ICLResponse<CurrencyCode[]>>(
      `api/Documents/GetCurrencyCodeAD/?companyId=${companyId}`,
      { headers }
    );
  }
  //crear documento
  CreateDocument(documentForm: UntypedFormGroup, itemsList: ILineaDetalle[], docId: number):Observable<CreateDocResponse>{

    const APPSESSION = this.storage.GetCurrentSession();
    const COMPANY = JSON.parse(this.storage.GetSelectedCompany());


    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API': 'ApiFEUrl'
    });

    let duplicateItemList = JSON.parse(JSON.stringify(itemsList));
    //Propiedades utilizadas solo de manera visual para mostrar en la tabla items, por eso se elimina de esta lista
    const itemsToDelete = ['NewCode', 'ProductPrice', 'Total'];
    duplicateItemList.forEach((item: any) => {
      itemsToDelete.forEach(x => {
        delete item[x];
      })
    });


    let TotalServGravados = 0;
    let TotalServExentos = 0;
    let TotalServExonerado = 0;

    let TotalMercanciasGravadas = 0;
    let TotalMercanciasExentas = 0;
    let TotalMercExonerada = 0;

    let TotalGravado = 0;
    let TotalExento = 0;
    let TotalExonerado = 0;

    let TotalVenta = 0;
    let TotalDescuentos = 0;
    let TotalVentaNeta = 0;
    let TotalImpuesto = 0;
    let TotalComprobante = 0;

    let TotalOtrosCargos = 0;
    let TotalIVADevuelto = 0;


    // porcentaje del campo Código Tarifa
    let codTaf = 0;
    const tarifa: any = {
      '02': () => codTaf = 1,
      '03': () => codTaf = 2,
      '04': () => codTaf = 4,
      '06': () => codTaf = 4,
      '07': () => codTaf = 8,
      '08': () => codTaf = 13,
      default: () => codTaf = 0,
    };


    duplicateItemList.forEach((item: ILineaDetalle, index: number) =>{

      (tarifa[item.ImpCodigoTarifa] || tarifa.default)();

      //porcentaje a exonerar
      let porc = 0;
      porc = item.EPorcentajeExoneracion && (item.EPorcentajeExoneracion / codTaf) * 100 || 0;

      item.NumeroLinea = index + 1;
      item.EFechaEmision = new Date(item.EFechaEmision).toISOString();

      if(item.ETipoDocumento !== '00'){
        //Si posee exoneracion
        let PorcExo = porc;
        let PorcNoExo = 100 - porc;

        if (item.CodTipo === '01') {
          // Producto
          TotalMercExonerada += (item.MontoTotal * PorcExo) / 100;
          TotalMercanciasGravadas += (item.MontoTotal * PorcNoExo) / 100;
        } else {
          // Servicio
          TotalServExonerado += (item.MontoTotal * PorcExo) / 100;
          TotalServGravados += (item.MontoTotal * PorcNoExo) / 100;
        }
      }else {
        if (item.ImpMonto === 0) {
          //esta excento de impuestos
          if (item.CodTipo === '01') {
            //Producto
            if (item.ImpCodigoTarifa === '01' || item.ImpCodigoTarifa === '05') {
              TotalMercanciasExentas += item.MontoTotal;
              item.ImpCodigo = '';
              item.ImpTarifa = null;
              item.ImpMonto = null;
            } else {
              TotalMercanciasGravadas += item.MontoTotal;
            }
          } else {
            // Servicio
            if (item.ImpCodigoTarifa === '01' || item.ImpCodigoTarifa === '05') {
              TotalServExentos += item.MontoTotal;
              item.ImpCodigo = '';
              item.ImpTarifa = null;
              item.ImpMonto = null;
            } else {
              TotalServGravados += item.MontoTotal;
            }
          }
        } else {
          //No esta excento de impuestos
          if (item.CodTipo === '01') {
            //Producto
            if (item.ImpCodigoTarifa === '01' || item.ImpCodigoTarifa === '05') {
              TotalMercanciasExentas += item.MontoTotal;
              item.ImpCodigo = '';
              item.ImpTarifa = null;
              item.ImpMonto = null;
            } else {
              TotalMercanciasGravadas += item.MontoTotal;
            }
          } else {
            // Servicio
            if (item.ImpCodigoTarifa === '01' || item.ImpCodigoTarifa === '05') {
              TotalServExentos += item.MontoTotal;
              item.ImpCodigo = '';
              item.ImpTarifa = null;
              item.ImpMonto = null;
            } else {
              TotalServGravados += item.MontoTotal;
            }
          }
        }

        item.EFechaEmision = '';
        item.EMontoExoneracion = null;
        item.ENombreInstitucion = '';
        item.ENumeroDocumento = '';
        item.EPorcentajeExoneracion = null;
        item.ETipoDocumento = '';
      }
      item.CodTipo = '04';
      TotalDescuentos += item.MontoDescuento;
      if (item.ImpuestoNeto !== null && item.ImpuestoNeto !== undefined) {
        TotalImpuesto += item.ImpuestoNeto;
      }

      if(item.ImpCodigo === '00'){
        item.ImpCodigo = '';
      }

      if(item.regalia){
        item.PrecioUnitario = 0;
      }

    });

    // se fixean a 5 decimales y se hace la suma respectiva
    TotalServGravados = parseFloat(TotalServGravados.toFixed(5));
    TotalServExentos = parseFloat(TotalServExentos.toFixed(5));
    TotalServExonerado = parseFloat(TotalServExonerado.toFixed(5));
    TotalMercanciasGravadas = parseFloat(TotalMercanciasGravadas.toFixed(5));
    TotalMercanciasExentas = parseFloat(TotalMercanciasExentas.toFixed(5));
    TotalMercExonerada = parseFloat(TotalMercExonerada.toFixed(5));
    TotalGravado = TotalServGravados + TotalMercanciasGravadas;
    TotalExento = TotalServExentos + TotalMercanciasExentas;
    TotalExonerado = TotalServExonerado + TotalMercExonerada;

    // se fixean a 5 decimales y se hace la suma respectiva
    TotalGravado = parseFloat(TotalGravado.toFixed(5));
    TotalExento = parseFloat(TotalExento.toFixed(5));
    TotalExonerado = parseFloat(TotalExonerado.toFixed(5));

    TotalVenta = TotalGravado + TotalExento + TotalExonerado;
    TotalVenta = parseFloat(TotalVenta.toFixed(5));

    TotalDescuentos = parseFloat(TotalDescuentos.toFixed(5));

    TotalVentaNeta = TotalVenta - TotalDescuentos;

    TotalVentaNeta = parseFloat(TotalVentaNeta.toFixed(5));
    TotalImpuesto = parseFloat(TotalImpuesto.toFixed(5));
    TotalIVADevuelto = parseFloat(TotalIVADevuelto.toFixed(5));
    TotalOtrosCargos = parseFloat(TotalOtrosCargos.toFixed(5));

    TotalComprobante =
      TotalVentaNeta + TotalImpuesto + TotalOtrosCargos + TotalIVADevuelto;
    TotalComprobante = parseFloat(TotalComprobante.toFixed(5));

    let docType = documentForm.controls['DocType'].value;

    let InfRefTipoDoc: string = documentForm.controls['InfRefTipoDoc'].value !== '0' && documentForm.controls['InfRefTipoDoc'].value !== '' ?
      documentForm.controls['InfRefTipoDoc'].value : '';

    let InfRefNumero: string = InfRefTipoDoc ? documentForm.controls['InfRefNumero'].value : '';
    let InfRefFechaEmision: string = InfRefTipoDoc ? new Date(documentForm.controls['InfRefFechaEmision'].value)?.toISOString() : '';
    let InfRefCodigo: string = InfRefTipoDoc ? documentForm.controls['InfRefCodigo'].value : '';
    let InfRefRazon: string = InfRefTipoDoc ? documentForm.controls['InfRefRazon'].value : '';

    let documento: Documento = {
      Id: docId,
      CodigoActividad: documentForm.controls['CodigoActividad'].value,
      CondicionVenta: documentForm.controls['CondicionVenta'].value,
      PlazoCredito: documentForm.controls['PlazoCredito'].value,
      MedioPago: documentForm.controls['MedioPago'].value,
      Situacion: 1,
      FechaFact:  this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss.ss'),
      ErrDetails: '',
      CompanyId: COMPANY.companyId,
      Sucursal: documentForm.controls['Sucursal'].value,
      Terminal: documentForm.controls['Terminal'].value,
      DocType: documentForm.controls['DocType'].value,
      ConsecutivoId: '0',
      Consecutivo: '0',
      EmsrNombre: docType === '08' ? documentForm.controls['RcprNombre'].value : '',
      EmsrIdeTipo: docType === '08' ? documentForm.controls['RcprIdeTipo'].value : '',
      EmsrIdeNumero: docType === '08' ? documentForm.controls['RcprIdeNumero'].value : '',
      EmsrNombreComercial: '',
      EmsrUbProvincia: docType === '08' ? documentForm.controls['RcprUbProvincia'].value : '0',
      EmsrUbCanton: docType === '08' ? documentForm.controls['RcprUbCanton'].value : '0',
      EmsrUbDistrito: docType === '08' ? documentForm.controls['RcprUbDistrito'].value : '0',
      EmsrUbBarrio: docType === '08' ? documentForm.controls['RcprUbBarrio'].value : '0',
      EmsrUbOtrasSenas: docType === '08' ? documentForm.controls['RcprUbOtrasSenas'].value : '0',
      EmsrTlfCodigoPais: 506,
      EmsrTlfNumTelefono: docType === '08' ? documentForm.controls['RcprTlfNumTelefono'].value : '',
      EmsrFaxCodigoPais: 506,
      EmsrFaxNumTelefono: docType === '08' ? documentForm.controls['RcprFaxfNumTelefono'].value : '',
      EmsrCorreoElectronico: docType === '08' ? documentForm.controls['RcprCorreoElectronico'].value : '',

      RcprNombre: documentForm.controls['RcprNombre'].value,
      RcprIdeTipo: docType !== '08' ? documentForm.controls['RcprIdeTipo'].value : '',
      RcprIdeNumero: docType !== '08' ? documentForm.controls['RcprIdeNumero'].value : '',
      RcprIdentificacionExtranjero: '',
      RcprNombreComercial: '',
      RcprUbProvincia: docType !== '08' ? documentForm.controls['RcprUbProvincia'].value : '',
      RcprUbCanton: docType !== '08' ? documentForm.controls['RcprUbCanton'].value : '',
      RcprUbDistrito: docType !== '08' ? documentForm.controls['RcprUbDistrito'].value : '',
      RcprUbBarrio: docType !== '08' ? documentForm.controls['RcprUbBarrio'].value : '',
      RcprUbOtrasSenas: docType !== '08' ? documentForm.controls['RcprUbOtrasSenas'].value : '',
      RcprOtrasSenasExtranjero: '',
      RcprTlfCodigoPais: 506,
      RcprTlfNumTelefono: docType !== '08' ? documentForm.controls['RcprTlfNumTelefono'].value : '',
      RcprFaxfCodigoPais: 506,
      RcprFaxfNumTelefono: docType !== '08' ? documentForm.controls['RcprFaxfNumTelefono'].value : '',
      RcprCorreoElectronico: docType !== '08' ? documentForm.controls['RcprCorreoElectronico'].value : '',
      RcprCorreoElectronicoCC: docType !== '08' ? documentForm.controls['RcprCorreoElectronicoCC'].value : '',

      InfRefTipoDoc: InfRefTipoDoc,
      InfRefNumero: InfRefNumero,
      InfRefFechaEmision: InfRefFechaEmision,
      InfRefCodigo: InfRefCodigo,
      InfRefRazon: InfRefRazon,

      CodigoMoneda: documentForm.controls['Currency'].value,
      TipoCambio: documentForm.controls['ExchangeRate'].value,
      TotalServGravados: TotalServGravados,
      TotalServExentos: TotalServExentos,
      TotalServExonerado: TotalServExonerado,
      TotalMercanciasGravadas: TotalMercanciasGravadas,
      TotalMercanciasExentas: TotalMercanciasExentas,
      TotalMercExonerada: TotalMercExonerada,
      TotalGravado: TotalGravado,
      TotalExento: TotalExento,
      TotalExonerado: TotalExonerado,
      TotalVenta: TotalVenta,
      TotalDescuentos: TotalDescuentos,
      TotalVentaNeta: TotalVentaNeta,
      TotalImpuesto: TotalImpuesto,
      TotalIVADevuelto: TotalIVADevuelto,
      TotalOtrosCargos: TotalOtrosCargos,
      TotalComprobante: TotalComprobante,
      OtroTexto: '',

      OtTipoDocumento: '',
      OtNumeroIdentidadTercero: '',
      OtNombreTercero: '',
      OtDetalle: '',
      OtPorcentaje: 0,
      OtMontoCargo: 0,

      V_LineaDetalle: duplicateItemList,
    }

    let docInfo: DocumentoWithUserId = {
      documento: documento,
      UserId: APPSESSION.userId,
    };


    return this.http.post<CreateDocResponse>(
      `api/Documents/CreateDocumentManual`,
      docInfo,
      { headers }
    );
  }

//  descarga un xml desde un b64
  // #REV# no eliminar
  GetXMLDocError(id: number):Observable<ICLResponse<ViewAcepXMLResponse>> {
    const URL = `api/Documents/GetXMLErrorDoc?docId=${id}`;
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<ViewAcepXMLResponse>>(URL, { headers });
  }

  // This method is used to send documents for reception.
  DocumentsReception(selectedFile: File, Recepcion: Reception): Observable<CreateDocResponse> {
    const APPSESSION = this.storage.GetCurrentSession();
    const token = this.storage.GetCurrentFESession();
    Recepcion.UserId = APPSESSION.userId;
    const url = `api/Documents/ReceptMessage`;

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('Recepcion', JSON.stringify(Recepcion));

    return this.http.post(url, formData, {
      headers: {
        //'Authorization': `Bearer ${token.access_token}`,
        'API': 'ApiFEUrl'
      }
    }).pipe(
      catchError(error => {

        const errorResponse: CreateDocResponse = {
          DocId: 0,
          HaciendaInfo: {Clave: "",CodRespuesta:"",Details:"",Estado:"",Fecha:"",RespuestaXML:"",Location:""},
          result: false,
          errorInfo: {
            Message: error.message,
            Code: error.code
          }
        };

        return of(errorResponse);
      }) as OperatorFunction<Object, CreateDocResponse>
    );
  }


  GetPreviewDocument(selectedFile: File):Observable<ICLResponse<DocumentPreview>> {
    var thisObj = this;

    const headers = new HttpHeaders({
      'Request-With-Files': 'true',
      'API': 'ApiAppUrl'
    });
    const fd: FormData = new FormData();

    fd.append('file', selectedFile);

    return this.http.post<ICLResponse<DocumentPreview>>(
      `api/Documents/GetPreviewDocument`,
      fd,
      { headers }
    );
  }


  // Funcion para ir a buscar el reporte y abrirlo en una pestaña nueva
  // recibe como parametro el objeto de busqueda de reportes
  GetDocReport(searchReport: SearchReport):Observable<ICLResponse<string>> {
    const URL = `api/Report/GetDocReport?StartDate=${searchReport.StartDate}&EndDate=${searchReport.EndDate}&CompanyId=${searchReport.CompanyId}`;
    const headers = new HttpHeaders({
      responseType: 'blob',
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<string>>(URL, { headers });
  }

  // Funcion para ir a buscar el reporte de recepcion y abrirlo en una pestaña nueva
  // recibe como parametro el objeto de busqueda de reportes
  GetDocReceptReport(searchReport: SearchReport):Observable<ICLResponse<string>> {
    const URL = `api/Report/GetDocReceptReport?StartDate=${searchReport.StartDate}&EndDate=${searchReport.EndDate}&CompanyId=${searchReport.CompanyId}`;
    const headers = new HttpHeaders({
      responseType: 'blob',
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<string>>(URL, { headers });
  }


  // metodo para cambiar el estado de un documento de a 'Cancelled' para anularlo internamente
  SetDocStatusInternalCancelled(docId: number): Observable<ICLResponse<BaseResponse>> {
    const TOKENFE = this.storage.GetCurrentFESession();

    let changeDocStatus: ChangeStatusDocFE = {
      docId: docId,
      feToken: TOKENFE.access_token,
    };
    const URL = `api/Documents/SetDocStatusInternalCancelled`;
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });

    return this.http.patch<ICLResponse<BaseResponse>>(URL, changeDocStatus, { headers });
  }


  // esta funcion se cambio para descargar no un archivo del server, sino un xml desde un b64
  // #REV# no eliminar
  GetInfoRecept(receptId: number): Observable<ICLResponse<DocumentXMLResponse>> {
    const URL = `api/Documents/GetInfoRecept?receptId=${receptId}`;
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<DocumentXMLResponse>>(URL, { headers });
  }
  //#region Charts
  // obtiene los documentos de ventas por dias (ultimos 30)
  GetDocsPerDays(docSearch: SearchStatusDoc):Observable<ICLResponse<DocumentsPerDaysModel[]>> {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<DocumentsPerDaysModel[]>>(
      `api/Documents/GetDocsPerDays/?companyId=${docSearch.CompanyId}`,
      { headers }
    );
  }


  // obtiene los documentos de ventas por semana (ultimas 12)
  GetDocsPerWeek(docSearch: SearchStatusDoc):Observable<ICLResponse<DocumentsPerWeekModel[]>> {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<DocumentsPerWeekModel[]>>(
      `api/Documents/GetDocsPerWeek/?companyId=${docSearch.CompanyId}`,
      { headers }
    );
  }

  // obtiene los documentos de aceptacion
  GetDocsPerMonth(docSearch: SearchStatusDoc):Observable<ICLResponse<DocumentsPerMonthModel[]>> {

    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });

    return this.http.get<ICLResponse<DocumentsPerMonthModel[]>>(
      `api/Documents/GetDocsPerMonth/?companyId=${docSearch.CompanyId}`,
      { headers }
    );
  }

  // obtiene el top ten de clientes por ventas
  GetTopTenCustomers(docSearch: SearchStatusDoc):Observable<ICLResponse<TopTenCustomer[]>> {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<TopTenCustomer[]>>(
      `api/Documents/GetTopTenCustomers/?companyId=${docSearch.CompanyId}`,
      { headers }
    );
  }

  // obtiene la informacion de los correos  (ultimos 30)
  GetEmailsForChart(companyId: number):Observable<ICLResponse<EmailsForChartModel[]>> {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<EmailsForChartModel[]>>(
      `api/Documents/GetEmailForChart/?companyId=${companyId}`,
      { headers }
    );
  }

  // obtiene la informacion de los documentos  (ultimos 30)
  GetDocumentsForChart(companyId: number):Observable<ICLResponse<DocumentsForChartModel[]>> {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<DocumentsForChartModel[]>>(
      `api/Documents/GetDocumentsForChart/?companyId=${companyId}`,
      { headers }
    );
  }

  //#endregion

  // Obtiene registros de la tabla OutgoingMails mediante filtros
  GetOutgoingMailsByFilters(companyId: number, startPos: number, rowsReturned: number, outgoingMailsForm: UntypedFormGroup): Observable<ICLResponse<OutgoingMailDetails[]>> {
    const params = new HttpParams()
      .set('CompanyId', companyId)
      .set('DocClave', outgoingMailsForm.value.DocClave)
      .set('DocType', outgoingMailsForm.value.DocType)
      .set('EmailOutputCC', outgoingMailsForm.value.EmailOutputCC)
      .set('EmailOutputTo', outgoingMailsForm.value.EmailOutputTo)
      .set('EmailStatus', outgoingMailsForm.value.EmailStatus)
      .set('EndDate', this.global.FormatDate(outgoingMailsForm.value.EndDate))
      .set('InitialDate', this.global.FormatDate(outgoingMailsForm.value.InitialDate))
      .set('RcprIdeNumero', outgoingMailsForm.value.RcprIdeNumero)
      .set('RcprNombre', outgoingMailsForm.value.RcprNombre)
      .set('startPos', startPos)
      .set('stepPos', rowsReturned);

    const URL = `api/Email/GetOutgoingMailsByFilters/`;

    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });

    return this.http.get<ICLResponse<OutgoingMailDetails[]>>(URL, { params, headers });
  }

}


