<div class="main">
  <div fxLayout="column wrap" style="margin-left: 200px; margin-right: 200px">
    <h3><img class="mx-6 my-5" src="assets/Logo-Clavisco-blue.png" alt="" width="300"></h3>
    <form [formGroup]="verificationForm">
      <div fxLayout="column wrap" class="mx-6">
        <div fxFlex="1" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Contraseña</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="verificationForm.controls['password'].errors?.['required']">
              La contraseña es requerida.
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Confirme la Contraseña</mat-label>
            <input matInput [type]="hideConfirmPass ? 'password' : 'text'" formControlName="confirmPassword">
            <button mat-icon-button matSuffix (click)="hideConfirmPass = !hideConfirmPass" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPass">
              <mat-icon>{{hideConfirmPass ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="verificationForm.controls['confirmPassword'].errors?.['required']">
              La confirmación de contraseña es requerida.
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="30" fxFlex.gt-sm="10" class="w-100">
          <button type="button" class="mt-btn px-3" mat-raised-button color="primary" (click)="OnSubmit()" [disabled]="verificationForm.invalid">
            <span class="material-icons-outlined" style="color:white;">
              Registrar
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
