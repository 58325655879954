<div class="main">
  <h3 mat-dialog-title>Agregar Bandeja Mail Parser</h3>
  <mat-dialog-content class="p-4">
    <div class="Header">
      <form [formGroup]="mailParserForm" class="mpForm">
        <div fxLayout="row wrap" fxLayoutAlign="space-between">
          <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="49" fxFlex.gt-sm="49" class="mt-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Direccion del servidor</mat-label>
              <input type="text" placeholder="Direccion del servidor" aria-label="Number" matInput
                     formControlName="ServerDirection">
              <mat-error *ngIf="mailParserForm.controls['ServerDirection']?.errors?.['required']">
                La direccion del servidor del servidor es requerida.
              </mat-error>
            </mat-form-field>
          </div>
          <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="49" fxFlex.gt-sm="49" class="mt-2">
            <mat-form-field appearance="outline" class="w-100" >
              <mat-label>Correo</mat-label>
              <input type="text" placeholder="Correo" aria-label="Number" matInput formControlName="Email">
              <mat-error *ngIf="mailParserForm.controls['Email']?.errors?.['required']">
                El correo es requerido.
              </mat-error>
            </mat-form-field>
          </div>
          <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="49" fxFlex.gt-sm="49" class="mt-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Compañia</mat-label>
              <input type="text"
                     placeholder="Pick one"
                     aria-label="Compañia"
                     matInput
                     formControlName="CompanyId"
                     [matAutocomplete]="auto">
              <mat-autocomplete [displayWith]="DisplayCompanyFn" autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option.ComercialName }}
                </mat-option>
              </mat-autocomplete>
              <mat-error
                *ngIf="mailParserForm.get('CompanyId')?.hasError('required') && mailParserForm.get('CompanyId')?.touched">
                La compañia es requerida.
              </mat-error>
            </mat-form-field>
          </div>
          <!--      </div>-->
          <!--      <div fxLayout="row wrap">-->
          <div *ngIf="!mailParserForm.controls['UseToken'].value" fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="49" fxFlex.gt-sm="49" class="mt-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Contraseña</mat-label>
              <input type="text" placeholder="Contraseña" aria-label="Number" matInput formControlName="Password">
              <mat-error *ngIf="mailParserForm.controls['Password']?.errors?.['required']">
                La contraseña es requerida .
              </mat-error>
            </mat-form-field>
          </div>
          <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="49" fxFlex.gt-sm="49">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Puerto</mat-label>
              <input type="number" placeholder="Puerto" aria-label="Number" matInput formControlName="Port">
              <mat-error *ngIf="mailParserForm.controls['Port']?.errors?.['required']">
                El puerto es requerido.
              </mat-error>
            </mat-form-field>
          </div>
          <!--      </div>-->
          <!--      <div fxLayout="row wrap">-->
          <div *ngIf="mailParserForm.controls['UseToken'].value" fxflex="100" fxFlex.xs="100" fxFlex.sm="100"
               fxFlex.md="49" fxFlex.gt-sm="49">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Tenant Id</mat-label>
              <input type="text" placeholder="Tenant Id" aria-label="Number" matInput formControlName="TenantId">
              <mat-error *ngIf="mailParserForm.controls['TenantId']?.errors?.['required']">
                El Tenant Id es requerido.
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="mailParserForm.controls['UseToken'].value" fxflex="100" fxFlex.xs="100" fxFlex.sm="100"
               fxFlex.md="49" fxFlex.gt-sm="49">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>URL</mat-label>
              <input type="text" placeholder="URL" aria-label="Number" matInput formControlName="URL">
              <mat-error *ngIf="mailParserForm.controls['URL']?.errors?.['required']">
                La URL es requerida.
              </mat-error>
            </mat-form-field>
          </div>
          <!--      </div>-->
          <!--        <div fxLayout="row wrap">-->
          <div *ngIf="mailParserForm.controls['UseToken'].value" fxflex="100" fxFlex.xs="100" fxFlex.sm="100"
               fxFlex.md="49" fxFlex.gt-sm="49">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>GrantType</mat-label>
              <input type="text" placeholder="GrantType" aria-label="Number" matInput formControlName="GrantType">
              <mat-error *ngIf="mailParserForm.controls['GrantType']?.errors?.['required']">
                El GrantType es requerido.
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="mailParserForm.controls['UseToken'].value" fxflex="100" fxFlex.xs="100" fxFlex.sm="100"
               fxFlex.md="49" fxFlex.gt-sm="49">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Scope</mat-label>
              <input type="text" placeholder="Scope" aria-label="Number" matInput formControlName="Scope">
              <mat-error *ngIf="mailParserForm.controls['Scope']?.errors?.['required']">
                El Scope es requerido.
              </mat-error>
            </mat-form-field>
          </div>
          <!--        </div>-->
          <!--        <div fxLayout="row wrap">-->
          <div *ngIf="mailParserForm.controls['UseToken'].value" fxflex="100" fxFlex.xs="100" fxFlex.sm="100"
               fxFlex.md="49" fxFlex.gt-sm="49">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>ClientSecret</mat-label>
              <input type="text" placeholder="ClientSecret" aria-label="Number" matInput formControlName="ClientSecret">
              <mat-error *ngIf="mailParserForm.controls['ClientSecret']?.errors?.['required']">
                El ClientSecret es requerido.
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="mailParserForm.controls['UseToken'].value" fxflex="100" fxFlex.xs="100" fxFlex.sm="100"
               fxFlex.md="49" fxFlex.gt-sm="49">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>ClientId</mat-label>
              <input type="text" placeholder="ClientId" aria-label="Number" matInput formControlName="ClientId">
              <mat-error *ngIf="mailParserForm.controls['ClientId'].errors?.['required']">
                El ClientId es requerido.
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="100">
            <mat-checkbox formControlName="Status">Activo</mat-checkbox>
          </div>
          <div fxFlex="100">
            <mat-checkbox formControlName="IsAutomatic">Automatico</mat-checkbox>
          </div>
          <div fxFlex="100">
            <mat-checkbox formControlName="UseToken">Usa token</mat-checkbox>
          </div>
        </div>
        <!--        <mat-grid-tile fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="25" fxFlex.gt-sm="25">-->
        <!--          <button type="button" class="mt-btn px-3" mat-raised-button color="primary" (click)="closeModal(false)" >-->
        <!--          <span class="material-icons-outlined" style="color:white;">-->
        <!--              Cancelar-->
        <!--          </span>-->
        <!--          </button>-->
        <!--        </mat-grid-tile>-->
        <!--      <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="49" fxFlex.gt-sm="100">-->
        <!--        <button type="button" class="mt-btn px-3" mat-raised-button color="primary" >-->
        <!--          <span class="material-icons-outlined" style="color:white;">-->
        <!--              Cancelar-->
        <!--          </span>-->
        <!--        </button>-->
        <!--      </div>-->
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" fxLayoutAlign="flex-end" fxLayoutAlign.sm="space-between" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap.xs="3px" fxLayoutGap.sm="3px">
    <button type="button" class="mt-btn ml-2" mat-flat-button color="primary" (click)="CloseModal(false)">
      <mat-icon>cancel</mat-icon>
      Cancelar
    </button>
    <button type="button" class="mt-btn" mat-flat-button color="primary" (click)="CreateOrUpdateMP(true)">
      <mat-icon>save</mat-icon>
      Guardar

    </button>
  </mat-dialog-actions>
</div>
