import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'udfMetadataType'
})
export class UdfMetadataType implements PipeTransform {

  transform(value: string): string {
    let mappedDataType = 'No definido';
    switch (value) {
      case 'String':
        mappedDataType = "Texto";
        break;
      case 'Int32':
        mappedDataType = "Numérico";
        break;
      case 'Double':
        mappedDataType = "Decimal";
        break;
      case 'DateTime':
        mappedDataType = "Fecha";
        break;
    }
    return mappedDataType;
  }

}
