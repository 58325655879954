import { Injectable } from '@angular/core';
import {CompaniesService} from "./companies.service";
import {HttpClient} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {BehaviorSubject, filter, fromEvent, map, Observable, tap} from "rxjs";
import {BaseResponse, CertExpireDateAlarmResponse} from "../../models/responses";
import {NumberingIntegrationTypes} from "../enums/enums";
import {CertExpireDateAlarm} from "../interfaces/i-company";
import {GetError, Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {CLModalType, ModalService} from "@clavisco/alerts";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {formatDate} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class GlobalFunctionsService {
  //@BlockUI() blockUI: NgBlockUI;
  public url = 'assets/data/';



  constructor(
    private companiesService: CompaniesService,
    //private alertService: AlertService,
    public http: HttpClient,
    private storageService: StorageService,
    private alertService: AlertsService,
    private modalService: ModalService,
  ) {}

  GetCertExpireDateAlarm(companyId: number):void {
    this.companiesService.GetCertExpireDateAlarm(companyId).pipe(
      map((data: ICLResponse<CertExpireDateAlarm>) => {
        // Realiza la conversión del tipo BaseResponse a CertExpireDateAlarmResponse aquí
        return data as ICLResponse<CertExpireDateAlarm>;
      })).subscribe({
      next:(data:ICLResponse<CertExpireDateAlarm>)=>{
        if(data.Data){
          if(data.Data.ShowAlarm){
            this.alertService.Toast({ message: data.Data.SmsAlert,
              type: CLToastType.WARNING, darkMode:true,horizontalPosition:"right", verticalPosition:"top" })
          }
        }else{
          this.alertService.Toast({ message: `${data.Message}`,
            type: CLToastType.WARNING, darkMode:true,horizontalPosition:"right", verticalPosition:"top" })
        }
      },
      error:(err):void => {
        this.modalService.Continue({
          title: 'Error al obtener la fecha de expiración del certificado',
          subtitle: GetError(err),
          type: CLModalType.ERROR
        });
      }
    });
  }

  ConvertEnumToArray<E>(e: any):any {
    const arrayObjects = [];
    // Retrieve key and values using Object.entries() method.
    for (const [propertyKey, propertyValue] of Object.entries(e)) {
      // Ignore keys that are not numbers
      if (!Number.isNaN(Number(propertyKey))) {
        continue;
      }

      // Add keys and values to array
      arrayObjects.push({ Id: propertyValue, Name: propertyKey });
    }
    return arrayObjects;
  }


  public CheckPermission(permissionName: string): boolean {

    let permissionList: string[] = JSON.parse(this.storageService.GetItem<string>('permissions'));

    if (permissionList && permissionList.length) {
      return permissionList.some((permission) => permission === permissionName);
    } else {
      return false;
    }
  }

  private escKeySubject:Observable<KeyboardEvent> = fromEvent<KeyboardEvent>(document, 'keydown').pipe(
    filter((event) => event.key === 'Escape')
  );

  escKeyPress(): Observable<KeyboardEvent> {
    return this.escKeySubject;
  }

  //Date Formatting Function (yyyy-MM-dd)
  FormatDate(_date: string | Date): string {
    if (_date) {
      return formatDate(_date, 'yyyy-MM-dd', 'en');
    } else {
      return formatDate(new Date(), 'yyyy-MM-dd', 'en');
    }

  }

  /**
   * Verifica si una cadena es nula, indefinida o vacía después de eliminar los espacios en blanco.
   *
   * @param value - La cadena a verificar, que puede ser de tipo string, null o undefined.
   * @returns true si la cadena es nula, indefinida o vacía; false en caso contrario.
   */
  isNullOrEmpty(value: string | null | undefined): boolean {
    return value === null || value === undefined || value.trim() === '';
  }

}
