import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BaseResponse, PermissionsSelectedResponse, PermsByRolResponse} from "../../models/responses";
import {Observable} from "rxjs";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {PermissionsSelectedModel} from "../interfaces/i-perms";

@Injectable({
  providedIn: 'root'
})
export class PermsService {

  constructor(private http: HttpClient) { }

// obtiene los permisos de la aplicacion
  GetPerms():Observable<ICLResponse<PermissionsSelectedModel[]>> {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<PermissionsSelectedModel[]>>(
      `api/Permission/GetPermissions`,
      { headers }
    );
  }

  // obtiene los permisos de la aplicacion por usuario
  GetPermsByUser(companyId: number):Observable<ICLResponse<PermissionsSelectedModel[]>> {
     const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<PermissionsSelectedModel[]>>(
      `api/Permission/GetPermsByUser?companyId=${companyId}`,
      { headers }
    );
  }

  // obtiene los permisos por un rol especifico
  GetPermsByRol(idRol: number):Observable<ICLResponse<number[]>> {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<number[]>>(
      `api/Permission/GetPermissionsByRol?idRol=${idRol}`,
      { headers }
    );
  }

  // funcion para guardar la asignacion de permisos en el rol selecionado
  SavePermsByRol(permsRolModel:any):Observable<ICLResponse<BaseResponse>> {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.post<ICLResponse<BaseResponse>>(
      `api/Permission/AssignPermByRol`,
      permsRolModel,
      { headers }
    );
  }
}
