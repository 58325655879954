import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MailParserService} from "../../../core/services/mail-parser.service";
import {catchError, finalize, Subject, Subscription, tap} from "rxjs";
import {AlertsService, CLModalType, CLToastType, ModalService} from "@clavisco/alerts";
import {FormControl, FormGroup} from "@angular/forms";
import {MailParserConfigModel, MailParserpaginator} from "../../../core/interfaces/i-mailParserConfig";
import {ICLTableState, MapDisplayColumns, MappedColumns} from "@clavisco/table";
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, Register, Run, StepDown} from "@clavisco/linker";
import {MatDialog} from "@angular/material/dialog";
import {MailParserModalComponent} from "./mail-parser-modal/mail-parser-modal.component";
import {GetError, Structures} from "@clavisco/core";
import {ExecutionType} from "../../../core/enums/enums";
import {ISucursal, ISucursalPaginator} from "../../../core/interfaces/i-sucursal";
import {Search} from "../../../models/search";
import {GeneralConfigsService} from "../../../core/services/general-configs.service";
import {OverlayService} from "@clavisco/overlay";
import {MailParserConfigResponse} from "../../../models/responses";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Component({
  selector: 'app-mail-parser-config',
  templateUrl: './mail-parser-config.component.html',
  styleUrls: ['./mail-parser-config.component.scss']
})
export class MailParserConfigComponent implements OnInit,AfterViewInit, OnDestroy {

  mailParserList: MailParserpaginator[] = [];
  // titulo de la vista
  title = 'Procesador de correo';
  mailParserForm?: FormGroup;
  Action?: string; // accion del boton de crear o modificar sucursal
  idCompany?: number; // numero del id de la sucursal
  modalReference: any; // instancia de la modal de creacion o edicion de sucursal
  closeResult?: string; // resultado de cerrar la modal

  formSearchMP: any;
  statusList: any[] = [{ Name: 'Ambos', Value: 2 },{ Name: 'Activo', Value: 1 }, { Name: 'Inactivo', Value: 0 }]; // lista de estados
  tokenList: any[] = [{ Name: 'Ambos', Value: 2 },{ Name: 'Si', Value: 1 }, { Name: 'No', Value: 0 }]; // lista de estados

  page = 1; // pagina actual
  stepPost = 10;

  mailHaveData?: boolean; // variable que me indica si la consulta trae data
  compMessage?: string; // variable para mostrar un mensaje en caso de que no se traiga data
  totalPages?: number; // cantidad total de paginas a mostrar
  mailListSplice: any[] = []; // lista de companias dividida en paginas
  mpListPaginator: MailParserConfigModel[] = [];
  firstEnter: boolean = true;
  isVisible: boolean = true;
  currentCompanySubscription?: Subscription;
  notifier = new Subject();

  buttons: any = [
    {
      Title: `Actualizar`,
      Action: Structures.Enums.CL_ACTIONS.UPDATE,
      Icon: `edit`,
      Color: `primary`,
    }
  ];



  mailServer: string | undefined; // variable para buscar por nombre de server
  email: string | undefined; // variable para buscar por correo
  server: string | undefined; // variable para puerto
  companyName: string | undefined; // variable para buscar nombre de la compania

  status: number | undefined;
  useToken: number | undefined;

  //Variables para la tabla
  tableIdIssuedDocument: string = 'issuedMailParserTable'; //(Requerido): Este identificador debe ser único para evitar cruce de registros cuando se use en el componente en una misma vista n cantidad de veces
  isStandarHeaders: boolean = false;
  shouldSplitPascal: boolean = false;
  displayedColumns!: MappedColumns; //(Requerido): objeto generado mediante la función MapDisplayColumns(...) que permite mapear las columnas renombradas, ignoradas y vacias de la tabla
  dataSource: MailParserpaginator[] = [];
  scrollHeight: string = '350px';
  pageSizeOptions: number[] = [5, 10, 15];
  itemsPeerPage: number = 5;
  recordsCount: number = 0;
  currentPage: number = 0;
  hasPaginator: boolean = true;
  shouldPaginateRequest: boolean = false;

  allSubscription!: Subscription;

  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };
  Name:string='Procesador de Correos';

  constructor(
    private mailParserService: MailParserService,
    @Inject('LinkerService') private linkerService: any,
    @Inject('OverlayService') private overlayService: OverlayService,
    public dialog: MatDialog,
    private alertService: AlertsService,
    private generalConfigsService:GeneralConfigsService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    this.allSubscription = new Subscription();

    this.displayedColumns = MapDisplayColumns({
      dataSource: [],
      renameColumns: {

        MailServer: 'Servidor',
        Email: 'Correo',
        Port: 'Puerto',
        Status: 'Estado',
        EmsrNombre: 'Nombre de la Compañía',
        UseToken: 'Usa Token',

      },
      ignoreColumns: [
        'ClientId',
        'ClientSecret',
        'GrantType',
        'Id',
        'IsAutomatic',
        'MaxQtyRowsFetch',
        'Password',
        'Scope',
        'TenantId',
        'Url',
        'CompanyId'
        ]
  });}

  ngOnDestroy(): void {
    this.allSubscription.unsubscribe();
    this.currentCompanySubscription?.unsubscribe();
  }
  ngAfterViewInit():void{
    this.generalConfigsService.SetNameAction(this.Name);
    this.cdr.detectChanges();
  }
  ngOnInit(): void {

    this.totalPages = 1;
    this.currentPage = 0;
    Register<CL_CHANNEL>(this.tableIdIssuedDocument, CL_CHANNEL.INPUT, this.GetElementsRecords, this.callbacks);
    Register<CL_CHANNEL>(this.tableIdIssuedDocument, CL_CHANNEL.OUTPUT, this.ButtonEvent, this.callbacks);

    this.allSubscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback:any) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error:any) => console.log(`Error`, error),
      }));

    this.LoadInitialData();
  }

  LoadInitialData(): void{
    //inicializacion de variables
    this.status = 2;
    this.useToken = 2;
    this.email = "";
    this.mailServer = "";
    this.companyName = "";



    this.formSearchMP = new FormGroup({
      Email: new FormControl(''),
      ServerName: new FormControl(''),
      Company: new FormControl(''),
      Status: new FormControl(this.statusList[0].Value),
      UseToken: new FormControl(this.tokenList[0].Value),
    })

    this.GetMailParser();
  }

GetMailParser(): void{

    //Se agrega overlay
    this.overlayService.OnGet();

    this.email = this.formSearchMP.get('Email').value;
    this.mailServer = this.formSearchMP.get('ServerName').value;
    this.companyName = this.formSearchMP.get('Company').value;
    this.status = this.formSearchMP.get('Status').value;
    this.useToken = this.formSearchMP.get('UseToken').value;




  this.mailParserService.GetMailParserPaginator(this.mailServer!,this.email!, this.companyName!, this.status!, this.useToken!, this.currentPage, this.itemsPeerPage).pipe(
    finalize(()=>this.overlayService.Drop())
  ).subscribe({
    next:(data:ICLResponse<MailParserpaginator[]>):void=>{
      this.mailParserList = [];
      if(data.Data.length){
        this.mailHaveData = true;
        //if (data.Data.length) {
          this.mailParserList = data.Data;
          //descomentar cuando funcione
          //this.totalPages = data.MaxQtyRowsFetch;

          this.alertService.Toast({ message: `Bandejas obtenidas con éxito!`, type: CLToastType.SUCCESS , darkMode:true,horizontalPosition:"right", verticalPosition:"top"})

          //this.pageChange();

        //} else {



       // }
        this.LoadTableData();
      }else{
        this.alertService.Toast({ message: `${data.Message}`, type: CLToastType.WARNING , darkMode:true,horizontalPosition:"right", verticalPosition:"top"})
      }
    }
  })
}

  LoadTableData(): void {
    const CURRENT_TABLE_STATE = {
      CurrentPage: this.currentPage,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.mailParserList,
      RecordsCount: this.mailParserList[0].MaxQtyRowsFetch,
    };

    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableIdIssuedDocument,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }


  ButtonEvent = (_event: ICLEvent): void => {
    if (_event.Data) {
      const BUTTON_EVENT = JSON.parse(_event.Data);
      const ELEMENT = JSON.parse(BUTTON_EVENT.Data);
      switch (BUTTON_EVENT.Action) {
        case Structures.Enums.CL_ACTIONS.UPDATE:
          this.OpenMPModal(ELEMENT, ExecutionType.Edit);
          break;
      }
    }
  }

  CreateMP(): void{
    let newMPConfig: any;
    this.OpenMPModal(newMPConfig, ExecutionType.Create)
  }

  OpenMPModal(MPModal: MailParserConfigModel, executionType: number): void {
    const dialogRef = this.dialog.open(MailParserModalComponent, {
      data: MPModal
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result === true){
        this.GetMailParser();
      }

    });
  }

  GetElementsRecords = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data) as ICLTableState<any>;
      this.itemsPeerPage = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.currentPage = INCOMMING_TABLE_STATE.CurrentPage ;
      this.GetMailParser();
    }
  };





}
