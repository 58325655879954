<div class="container">
  <div class="Base mb-3" fxLayout="row wrap" fxLayoutAlign="space-around">
    <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.gt-sm="100">

      <mat-card-content>
        <form [formGroup]="formSearchMP" fxLayout="row wrap" fxLayoutAlign="space-around" class="border">

          <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="32" fxFlex.gt-sm="32">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Correo</mat-label>
              <input matInput type="text" placeholder="Email" formControlName="Email">
            </mat-form-field>
          </div>

          <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="32" fxFlex.gt-sm="32">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Nombre del servidor</mat-label>
              <input matInput type="text" placeholder="ServerName" formControlName="ServerName">
            </mat-form-field>
          </div>

          <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="32" fxFlex.gt-sm="32">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Compañía</mat-label>
              <input matInput type="text" placeholder="Company" formControlName="Company">
            </mat-form-field>
          </div>

          <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="32" fxFlex.gt-sm="32">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Estado</mat-label>
              <mat-select formControlName="Status">
                <mat-option *ngFor="let status of statusList" [value]="status.Value">{{status.Name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="32" fxFlex.gt-sm="32">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Usa Token</mat-label>
              <mat-select formControlName="UseToken">
                <mat-option *ngFor="let token of tokenList" [value]="token.Value">{{token.Name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="16" fxFlex.gt-sm="15" ngClass.sm="mb-3">
            <button type="button" mat-raised-button class="mt-btn  w-100 mb-1" color="primary"
                    (click)="GetMailParser()">
              <mat-icon>filter_alt</mat-icon>
              <span class="material-icons-outlined">
                Consultar
              </span>
            </button>
          </div>

          <div fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="16" fxFlex.gt-sm="15">
            <button type="button" mat-raised-button class="mt-btn w-100" color="primary" (click)="CreateMP()">
              <mat-icon>add</mat-icon>
              <span class="material-icons-outlined">
                Crear Nuevo
              </span>
            </button>
          </div>
        </form>
      </mat-card-content>

    </div>
  </div>

  <div class="table-databases">
    <section class="table-databases-section mat-elevation-z0 border" tabindex="0">
      <cl-table class="table-database-content" [Id]="tableIdIssuedDocument" [HasStandardHeaders]="isStandarHeaders"
                [ShouldSplitPascal]="shouldSplitPascal" [MappedColumns]="displayedColumns" [Records]="dataSource"
                [PageSizeOptions]="pageSizeOptions" [ItemsPeerPage]="itemsPeerPage" [RecordsCount]="recordsCount"
                [HasPaginator]="hasPaginator" [ScrollHeight]="scrollHeight" [Buttons]="buttons"
                [ShouldPaginateRequest]="shouldPaginateRequest">
      </cl-table>
    </section>
  </div>

</div>









