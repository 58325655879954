import { Injectable } from '@angular/core';
import {StorageService} from "./storage.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {BehaviorSubject, map, Observable} from "rxjs";
import {InactiveUsers, IRegisterUser} from "../interfaces/i-user";
import {BaseResponse, FeCredentialsResponse} from "../../models/responses";
import {FeCredentialsModel, TokenFE} from "../../models/FeCredentialsModel";
import {PermissionsSelectedModel} from "../interfaces/i-perms";
import {Router} from "@angular/router";
import {UntypedFormGroup} from "@angular/forms";
import {FormGroup} from "@angular/forms";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<any> =  new BehaviorSubject<any>(null); // Inicialización con un valor predeterminado (puede ser null u otro valor)
  public currentUser: Observable<any>;

  private currentCompanySubject: BehaviorSubject<any> = new BehaviorSubject<any>(null); // Inicialización con un valor predeterminado (puede ser null u otro valor)
  public currentCompany: Observable<any>;

  private currentPermsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null); // Inicialización con un valor predeterminado (puede ser null u otro valor)
  public currentPerms: Observable<any>;
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router,) {

    this.currentUser=this.currentUserSubject.asObservable();
    this.currentCompany = this.currentCompanySubject.asObservable();
    this.currentPerms = this.currentPermsSubject.asObservable();

  }

  public get currentUserValue():BehaviorSubject<any> {
    return this.currentUserSubject.value;
  }

  public SetCurrentUserSubject(newVal: any): void {
    this.currentUserSubject.next(newVal);
  }
  public SetCurrentCompanyValue(newVal: any): void {
    this.currentCompanySubject.next(newVal);
  }

  public get getCurrentCompanyValue(): BehaviorSubject<any> {
    return  this.currentCompanySubject.value;
  }

  // metodo para desloguearse, elimina la sesion del localstorage y oculta el sidemenu
  logout() {
    this.storageService.RemovPerms();
    this.storageService.RemoveCurrentSession();
    this.storageService.RemoveCurrentFESession();
    this.storageService.RemoveSelectedCompany();
    this.storageService.RemoveFirstLogin();
    this.currentUserSubject.next(null);
    this.currentCompanySubject.next(null);
    this.currentPermsSubject.next(null);
    this.router.navigate(['/login']);
  }

  // verificacion de correo del usuario propietario de la cuenta
  GetFeCredentials(companyId: number,_token: string): Observable<ICLResponse<FeCredentialsModel[]>> {

    // const token = JSON.parse(this.storage.GetCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${_token}`,
      'API': 'ApiAppUrl'
    });
    return this.http.get<ICLResponse<FeCredentialsModel[]>>(
      `api/Credentials/GetFeCredentials?companyId=${companyId}`,
      { headers }
    );
  }

  // verificacion de correo del usuario propietario de la cuenta
  //revisar si es el mismo que la función anterior a este de manera que se pueda unir
  getFeCredentialsCompany(companyId: number): Observable<any> {
    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });
    return this.http.get(
      `api/Credentials/GetFeCredentials?companyId=${companyId}`,
      { headers }
    );
  }

  // metodo para activar un usuario
  resendConfirmEmail(user: InactiveUsers): Observable<ICLResponse<BaseResponse>> {

    const headers = new HttpHeaders({
      'API': 'ApiAppUrl'
    });

    return this.http.post<ICLResponse<BaseResponse>>(
      `api/Account/ResendConfirmEmail`,
      user,
      { headers }
    );
  }

  logoutVerificationEmail(): void{
    this.storageService.RemoveCurrentSession();
    this.storageService.RemoveCurrentFESession();
    this.storageService.RemoveSelectedCompany();
    this.currentUserSubject.next(null);
    this.currentCompanySubject.next(null);
    this.currentPermsSubject.next(null);
  }

  //verificacion de correo del usuario propietario de la cuenta
  ConfirmEmail(token: string): Observable<ICLResponse<BaseResponse>>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'API': 'ApiAppUrl'
    });
    return this.http.patch<ICLResponse<BaseResponse>>(
      `api/Account/ConfirmEmail`,
      { headers }
    );
  }


  //verificacion de correo del usuario propietario de la cuenta
  ConfirmEmailOwnerAccount(token: string, userForm: string): Observable<ICLResponse<BaseResponse>>{

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'API': 'ApiAppUrl'
    });
    const encodedUserForm = encodeURIComponent(userForm);
    return this.http.patch<ICLResponse<BaseResponse>>(
      `api/Account/ConfirmEmailInOwnerAccount?password=${encodedUserForm}`,
      null,
      { headers }
    );
  }


  // metodo para eliminar el token de fe
  removeFESesion() {
    this.storageService.RemoveCurrentFESession();
  }

  // funcion para obtener el token de FE
  // no recibe parametros
  getFEToken(FeCredentials: FeCredentialsModel): Observable<TokenFE> {

    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', FeCredentials.UserId)
      .set('password', FeCredentials.Password);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'API': 'ApiFEUrl'
    });

    return this.http
      .post<TokenFE>(`token`, body.toString(), { headers })
      .pipe(
        map((user) => {
          // login successful if there's a owin token in the response
          if (user && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.storageService.SetCurrentFESession(user);
          }
          return user;
        })
      );
  }


  public setCurrentPermsValue(newVal: PermissionsSelectedModel[]): any {
    this.currentPermsSubject.next(newVal);
  }

// funcion para el registro del usuario,
  // recibe como parametros el usuario y contrasenna
  signin(user: IRegisterUser): Observable<BaseResponse> {
    //    const userDB = new UserDB(user.value.email, user.value.password, user.value.fullName, true);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API': 'ApiAppUrl'
    });
    return this.http.post<BaseResponse>(
      `api/Account/RegisterUser/`,
      user,
      { headers }
    )
  }


  // Verificacion si un usuario esta logueado y el token no ha expirado aun
  public IsAuthenticated(): boolean {
    if (this.storageService.GetCurrentSession === null) return false;
    const TOKEN:any = this.storageService.GetCurrentSession();

    if (TOKEN) {
      var parts = TOKEN.expires.split(' ');

      var datePart = parts[0];
      var timePart = parts[1];

      var dateParts = datePart.split('/');
      var day = dateParts[0];
      var month = dateParts[1];
      var year = dateParts[2];

      var formattedDate = year + '/' + month + '/' + day;

      var newDateStr = formattedDate + ' ' + timePart;

      const expires: Date = new Date(newDateStr);
      const today: Date = new Date();
      const currentUTCDate: Date = new Date(today.getTime() + today.getTimezoneOffset() * 60000); // convertir a UTC
      if (currentUTCDate <= expires) { // posee token y el mismo aun no expira, es valido
        return true;
      }
    }
    return false;
  }


  // Verificacion si un usuario esta logueado y el token no ha expirado aun en el api de FE
  public IsFEAuthenticated(): boolean {
    if (this.storageService.GetCurrentFESession() === null) return false;
    const TOKEN:TokenFE = this.storageService.GetCurrentFESession();
    if (TOKEN) {
      const expires: Date = new Date(TOKEN.ExpireTime);
      const today: Date = new Date();
      if (today <= expires) { // posee token y el mismo aun no expira, es valido
        return true;
      }
    }
    return false;
  }
}
