import { Pipe, PipeTransform } from '@angular/core';
import {RolsModel} from "../models/roles";

@Pipe({
  name: 'activeRoles'
})
export class ActiveRolesPipe implements PipeTransform {

  transform(roles: RolsModel[]): RolsModel[] {
    return roles?.filter(role => role.Active);
  }

}
