import { Injectable } from "@angular/core";

@Injectable()
export class Globals {
  viewParamListSubMenu: any[] = []; // llena la lista con los permisos parametrizados del submenu principal
  viewParamListMenu: any[] = []; // llena la lista con los permisos parametrizados del menu principal
  permList: any[] = []; // llena la lista con los permisos parametrizados de la aplicacion
  compVisivility: any[] = [];
  componentInUse: any;
}
