import {
  DocumentAcceptedModel,
  DocumentModel, DocumentPreview,
  DocumentsAcceptedPaginatorModel,
  DocumentsForChartModel,
  DocumentsPerDaysModel,
  DocumentsPerMonthModel,
  DocumentsPerWeekModel,
  DocumentsQuantitiesModel,
  TopTenCustomer
} from "./Document";
import {EmailsForChartModel, OutGoingMails} from "./Email";
import {AllCompanyInfo, CompanyWithDefaultModel} from "./Company";
import {IGroup} from "../core/interfaces/i-group";
import {ISAPConnection} from "../core/interfaces/i-sapconnection";
import {ITax} from "../core/interfaces/i-tax";
import {InactiveUsers, IUser, IUsersByGroup, IUsersByOwnerGroup, UserByCompany, Users} from "../core/interfaces/i-user";
import {ICompanyInfo, ICompanyPaginator, ICompanyXUser} from "../core/interfaces/i-company";
import {ICountry, IProvince, ISucursal, ISucursalPaginator} from "../core/interfaces/i-sucursal";
import {Supplier} from "./supplier";
import {INumbering, IViewNumbering} from "../core/interfaces/i-numbering";
import {IReceptNumbering} from "../core/interfaces/i-recept-numbering";
import {
  ChargesReceptXMLInfo,
  DocReceptXMLInfo,
  DocSAPInfoLines,
  DocTypeBaseModel,
  FreightModel
} from "./ProviderDocument";
import {GenericType, Item, ItemList} from "../core/interfaces/i-item";
import {Warehouse} from "../core/interfaces/i-warehouse";
import {AccountModel} from "../core/interfaces/i-account";
import {ICustomer} from "../core/interfaces/i-customer";
import {PermissionsSelectedModel} from "../core/interfaces/i-perms";
import {CurrencyCode} from "../core/interfaces/i-CurrencyCode";
import {EmailProcessorLogModel} from "../core/interfaces/i-log";
import {RolByUserByCompModel, RolsModel} from "./roles";
import {GeneralConfigsModel} from "../core/interfaces/i-general-config";
import {MailParserConfigModel} from "../core/interfaces/i-mailParserConfig";
import {IDimensions} from "../core/interfaces/i-dimensions";
import {MatchAutomaticModel} from "../core/interfaces/i-match-automatic";
import {FeCredentialsModel, TokenFE} from "./FeCredentialsModel";
import {Banner} from "../core/interfaces/i-banner";
import {IEmailConfig} from "../core/interfaces/i-email-inbox";

export interface BaseResponse {
  result: boolean;
  errorInfo: ErrorInfo;
}

export interface ErrorInfo {
  Code: number;
  Message: string;
}
export interface CurrencyCodeDEResponse extends BaseResponse{
  CurrencyCodeList: CurrencyCode[];
}
export interface OutgoingMailsResponse extends BaseResponse {
  Correos: OutGoingMails[];
}
export interface DocumentResponse extends BaseResponse {
  DocumentList: DocumentModel[];
  DocumentQtyList: DocumentsQuantitiesModel[];
}

export interface CreateDocResponse extends BaseResponse {
  // numero documento interno Clavis
  DocId: number;
  HaciendaInfo: HaciendaResponse;
}

// modelo para obtener la respuesta de los usuarios que posee el owner de la compannia
export interface UsersByGroupResponse extends BaseResponse {
  UsersByGroupList: IUsersByGroup[];
}

// modelo de respuesta con la info del usuario
export interface UserInfoResponse extends BaseResponse {
  userInfo: Users;
}

// modelo para obtener la respuesta de los usuarios inactivos
export interface InactiveUsersResponse extends BaseResponse {
  InactiveUsersList: InactiveUsers[];
}

// modelo para obtener la respuesta de los usuarios que posee el owner de la compannia
export interface UsersByOwnerGroupResponse extends BaseResponse {
  UsersByOwnerList: IUsersByOwnerGroup[];
}

// modelo para obtener la respuesta de la asignacion de usuarios por compañías
export interface CompanyXUserResponse extends BaseResponse {
  CompanyXUserList: ICompanyXUser[];
  CompanyList: ICompanyInfo[];
}


// modelo para obtener las compannias de la base de datos local
export interface CompaniesResponse extends BaseResponse {
  CompanyList: ICompanyPaginator[];
  MaxQtyRowsFetch:number;
}

// modelo para obtener una compannia de la base de datos local
export interface CompanyInfoResponse extends BaseResponse {
  Company: CompanyWithDefaultModel;
}

// modelo para obtener los clientes por compannia de la base de datos local
export interface CustomersResponse extends BaseResponse {
  CustomerList: ICustomer[];
}


// respuesta de la obtencion de la info de SAP
export interface GetDocsInfoSAPResponse extends BaseResponse {
  DocLines: DocSAPInfoLines[];
}
export interface TaxesResponse extends BaseResponse {
  Taxes: ITax[];
}

export interface GroupsResponse extends BaseResponse {
  GroupsList: IGroup[];
  MaxQtyRowsFetch:number;
}

export interface CertExpireDateResponse extends BaseResponse {
  CertExpireDate: Date;
}
export interface CertExpireDateAlarmResponse extends BaseResponse {
  showAlarm: boolean;
  smsAlert: string;
}
// modelo para obtener los documentos aceptacon de la base de datos local
export interface DocumentAcceptedResponse extends BaseResponse {
  DocumentList: DocumentsAcceptedPaginatorModel[];
  DocumentQtyList: DocumentsQuantitiesModel[];
}

export interface SAPConnectionsResponse extends BaseResponse {
  sapConList: ISAPConnection[];
}

// respuesta de la obtencion de la lista de almacenes
export interface WarehouseListResponse extends BaseResponse {
  WhList: Array<Warehouse>;
}

export interface IResponse<T> extends BaseResponse {
  Data: T;
}
export interface DownloadPDFFileResponse extends BaseResponse {
  File: string;
}

export interface CreateDocumentResponse extends BaseResponse {
  // numero documento interno Clavis
  DocId: number;
  HaciendaInfo: HaciendaResponse;
}

//modelo de respuesta para obtener una lista de sucursales
export interface SucursalListResponse extends BaseResponse {
  // Lista con las sucursales para la company seleccionada
  SucursalList: ISucursal[];
}

//modelo de respuesta para obtener una sucursal
export interface SucursalResponse extends BaseResponse {
  // Sucursal seleccionada
  Sucursal: ISucursal;
}
export interface TaxesResponse extends BaseResponse {
  Taxes: Array<ITax>;
}
export interface SuppliersResponse extends BaseResponse {
  supplierList: Array<Supplier>;
}

// modelo para obtener las numeraciones por compannia de la base de datos local
export interface NumberingListResponse extends BaseResponse {
  NumberingList: INumbering[];
}

// modelo para obtener las numeraciones de recepción por compannia de la base de datos local
export interface ReceptNumberingListResponse extends BaseResponse {
  ReceptNumberingList: IReceptNumbering[];
}

// modelo para obtener una numeracion de recepción por id de la base de datos local
export interface ReceptNumberingResponse extends BaseResponse {
  ReceptNumbering: IReceptNumbering;
}

// modelo de respuesta para obtener una lista de terminales y de sucursales
export interface TerminalSucursalResponse extends BaseResponse {
  // Lista con las terminales y sucursale para la companya selecciona y segun el tipo de documento
  TerminalSucursalList: IViewNumbering[];
}

export interface NumberingResponse extends BaseResponse {
  Numbering: INumbering;
}

// respuesta de la obtencion de la info del XML
export interface GetDocAPInvoiceInfoXMLResponse extends BaseResponse {
  DocReceptXMLInfo: DocReceptXMLInfo;
}
export interface GetDocAPInvoiceChargesXMLResponse extends BaseResponse {
  DocChargesXMLInfo: ChargesReceptXMLInfo;
  // ChargesReceptXMLLines:ChargesReceptXMLLines
}
export interface DocTypeBaseResponse extends BaseResponse {
  // Lista de tipos de documentos base
  docTypeBaseList: DocTypeBaseModel[];
}
// respuesta de la obtencion de la lista de items
export interface ItemListResponse extends BaseResponse {
  itemList: Array<Item>;
}
// respuesta de la obtencion de la lista de almacenes
export interface WarehouseListResponse extends BaseResponse {
  WhList: Array<Warehouse>;
}
// modelo para devolver la lista de cuentas de SAP
export interface GetAccountListResponse extends BaseResponse {
  AccountList: Array<AccountModel>;
}
//modelo para devolver la informcaion de recepcion y creacion de un apInvoice
export interface ReceptAndApInvResponse extends BaseResponse {
  //obtiene la Respuesta del ApInvoice
  ApInvoiceResponse: CreateAPInvoiceResponse;
  //obtiene la informacion de recepcio
  Reception: CreateReceptResponse;
}

// respuesta de la creacion de la factura de proveedor en SAP
export interface CreateAPInvoiceResponse extends BaseResponse {
  //docnum del documento creado en SAP
  DocNum: number;
  //DocEntry del doc principal
  DocEntry: number;
}

export interface CreateReceptResponse extends BaseResponse {
  //Identificador de la acpetacion
  AcceptId: number;
  //Informacion de hacienda
  HaciendaInfo: HaciendaResponse;
}

export interface HaciendaResponse {
  // codigo respuesta hacienda
  CodRespuesta: string;
  // estado de documento en hacienda
  Estado: string;
  // clave generada documento en hacienda
  Clave: string;
  // fecha de documento en hacienda
  Fecha: string;
  // location hacienda
  Location: string;
  // respuesta hacienda
  RespuestaXML: string;
  // detalles
  Details: string;
}

export interface HaciendaResponse {
  CodRespuesta: string;
  Estado: string;
  Clave: string;
  Fecha: string;
  Location: string;
  RespuestaXML: string;
  Details: string;
}


export interface PermissionsSelectedResponse extends BaseResponse {
  PermissionsList: PermissionsSelectedModel[];
}

export interface MailParserLogResponse extends BaseResponse {
  EmailProcessorLogList: EmailProcessorLogModel[];
}


// modelo para obtener el historial de los documentos de 30 dias antes de la fecha actual de la base de datos local
export interface DocumentsPerDayResponse extends BaseResponse {
  DocumentList: DocumentsPerDaysModel[];
}

// modelo para obtener el historial de los documentos de 12 semanas antes de la fecha actual de la base de datos local
export interface DocumentsPerWeekResponse extends BaseResponse {
  DocumentList: DocumentsPerWeekModel[];
}

// modelo para obtener los documentos aceptacon de la base de datos local
export interface DocumentsPerMonthResponse extends BaseResponse {
  DocumentList: DocumentsPerMonthModel[];
}

// modelo para obtener la respuesta del top ten de clientes por venta
export interface TopTencustomerResponse extends BaseResponse {
  CustomerList: TopTenCustomer[];
}

//Modelo para obtener la informacion de los correos del grafico
export interface EmailsForChartResponse extends BaseResponse {
  MailsForChartsList: EmailsForChartModel[];
}

//Modelo para obtener la informacion de los documentos del grafico
export interface DocumentsForChartResponse extends BaseResponse {
  DocumentsForChartsList: DocumentsForChartModel[];
}


// modelo de respuesta con los roles
export interface RolsResponse extends BaseResponse {
  RolsList: RolsModel[];
}
// modelo de respuesta con la asignacion de rol, usuario, compañia
export interface RolUserCompanyResponse extends BaseResponse {
  rolUserCompList: RolByUserByCompModel[];
}


// modelo para obtener las configuraciones generales de la base de datos local
export interface GeneralConfigsResponse extends BaseResponse {
  GeneralConfigs: GeneralConfigsModel;
}


// modelo para obtener la respuesta XML del documento de la base de datos local
export interface DocumentXMLResponse extends BaseResponse {
  HrRespuestaXml: string;
}

export interface MailParserConfigResponse extends BaseResponse {
  // Lista con las bandejas
  MailParserList: MailParserConfigModel[];
  MaxQtyRowsFetch:number;
}


export interface AllCompaniesResponse extends BaseResponse {
  // Lista con las bandejas
  CompanyList: AllCompanyInfo[];
}

// modelo de respuesta con los permisos
export interface PermissionsSelectedResponse extends BaseResponse {
  PermissionsList: PermissionsSelectedModel[];
}


// modelo para obtener las dimenciones y sus centros de costo
export interface DimensionsResponse extends BaseResponse {
  DimensionAndCenterCost: IDimensions[];
}

// modelo para obtener las dimenciones y sus centros de costo
export interface PreviewResponse extends BaseResponse {
  Data: DocumentPreview;
}

// modelo para obtener la informacion de previsulizacion del doc xml
export interface PreviewResponseForXML extends BaseResponse {
  docBase: DocumentPreview;
}

// modelo para obtener los permisos por rol
export interface PermsByRolResponse extends BaseResponse {
  PermsByRolList: number[];
}

// modelo para obtener los usuarios por compania
export interface UserByCompanyResponse extends BaseResponse {
  UserByCompanyList: UserByCompany[];
}

// modelo para obtener el xml de aceptacion
export interface ViewAcepXMLResponse extends BaseResponse {
  HrRespuestaXml: string;
  XmlSent:string;
}

// modelo para obtener las lineas del matchAutomatico
export interface  MatchAutomaticResponse extends BaseResponse {
  MatchAutomaticList: MatchAutomaticModel;
}

// modelo para obtener los cargos adicionales en otros cargos
export interface  FreightResponse extends BaseResponse {
  AdditionalFreights: FreightModel[];
}

// modelo para obtener las credenciales de FE
export interface  FeCredentialsResponse extends BaseResponse {
  FeCredentials: FeCredentialsModel;
}

// modelo para obtener el token de inicio de sesion
export interface  TokenResponse extends BaseResponse {
  Token: TokenFE;
}

// modelo para obtener los paises
export interface  CountryResponse {
  Country: ICountry[];
}

// modelo para obtener las provincias
export interface  ProvinceResponse {
  Provinces: IProvince[];
}
// modelo para obtener una lista de items
export interface  ItemListCreateResponse extends BaseResponse{
  ItemList: ItemList[];
}
// modelo para obtener los tipos de impuesto
export interface  ImpuestoTypeResponse {
  ImpuestoType: GenericType[];
}
// modelo para obtener las unidades de medida
export interface  UnidadMedidaTypeResponse {
  UnidadMedidaType: GenericType[];
}

//Modelo para obtener la informacion del banner
export interface BannerResponse{
  //objeto que contiene la informacion del banner
  Data: Banner[];
}

/**
 * Represents the response containing a list of email configurations.
 */
export interface EmailConfigListResponse extends BaseResponse {
  /**
   * The list of email configurations returned in the response.
   */
  emailConfigList: IEmailConfig[];
}

/**
 * Represents the response containing email inboxes assigned and not assigned to a company.
 *
 * @extends BaseResponse - Base response interface that includes common response properties.
 */
export interface EmailInboxesByCompanyResponse extends BaseResponse {
  /**
   * List of email configurations that are assigned to the company.
   */
  ListEmailInboxesAssigned: IEmailConfig[];

  /**
   * List of email configurations that are not assigned to the company.
   */
  ListEmailInboxesNotAssigned: IEmailConfig[];
}

export interface HostResponse extends BaseResponse {
  HostList:string[];
}
