/*import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import {CommonModule} from "@angular/common";
import {AppSettings} from "./app.settings";
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesComponent } from './pages/pages.component'
import {APP_ROUTING} from "./app.routing";
import {LinkerService} from "@clavisco/linker";*/
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {LinkerModule, LinkerService} from '@clavisco/linker';
import { AppSettings } from './app.settings';
import { PagesComponent } from './pages/pages.component';
import {ContainerMenuComponent} from "./shared/menu/menu.component";
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { ContentReceptMessageComponent } from './pages/matDialog/content-recept-message/content-recept-message.component';
import {ReactiveFormsModule} from "@angular/forms";
import {UdfMetadataType} from "./pipes/udfMetadataType.pipe";
import {CurrencyPipe, DatePipe, NgOptimizedImage} from "@angular/common";
import {Globals} from "./Globals";
import {Miscellaneous} from "@clavisco/core";
// Componentes


// RUTAS
import {APP_ROUTING} from "./app.routing";
import {AlertsModule, AlertsService} from "@clavisco/alerts";
import {OverlayModule, OverlayService} from "@clavisco/overlay";
import { MailParserConfigComponent } from './pages/configuration/mail-parser-config/mail-parser-config.component';
import { ChartModalDocumentsComponent } from './pages/documents/chart-modal-documents/chart-modal-documents.component';
import {SharedModule} from "./shared/shared.module";
import {VerificationEmailComponent} from "./pages/configuration/verification-email/verification-email.component";
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import {LoginInterceptor} from "./Interceptors/login.interceptor";
import {UrlInterceptor} from "./Interceptors/url.interceptor";
import {ErrorInterceptor} from "./Interceptors/error.interceptor";
import { DocumentsAcceptedGtComponent } from './pages/documents/documents-accepted-gt/documents-accepted-gt.component';
import {BannerDialogComponent} from "./pages/matDialog/banner-dialog/banner-dialog.component";
import { EmailInboxComponent } from './pages/configuration/email-inbox/email-inbox.component';
import { EmailInboxConfigComponent } from './pages/configuration/email-inbox/email-inbox-config/email-inbox-config.component';
import { EmailInboxAssigmentComponent } from './pages/configuration/email-inbox/email-inbox-assigment/email-inbox-assigment.component';

@NgModule({
    declarations: [
        AppComponent,
        PagesComponent,
        ContainerMenuComponent,
        ConfigurationComponent,
        ContentReceptMessageComponent,
        UdfMetadataType,
        MailParserConfigComponent,
        ChartModalDocumentsComponent,
        VerificationEmailComponent,
        BannerDialogComponent,
    ],
    imports: [
        BrowserModule,
        SharedModule,
        LinkerModule,
        OverlayModule,
        AlertsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        APP_ROUTING,
        ReactiveFormsModule,
        NgOptimizedImage,

    ],
    providers: [
        AppSettings
        , CurrencyPipe,
        Globals,
        {
            provide: 'LinkerService',
            useClass: LinkerService
        },
        {
            provide: 'AlertsService',
            useClass: AlertsService
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Miscellaneous.Interceptors.HttpAlertInterceptor,
          multi: true
        },
        {
            provide: 'OverlayService',
            useClass: OverlayService
        },
      {provide: RECAPTCHA_V3_SITE_KEY,
      useValue: "6LdjSSsqAAAAAEwjXp07Zix1WxdgxgDGnvi889Io"},

        {
            provide: HTTP_INTERCEPTORS,
            useClass: Miscellaneous.Interceptors.PagedRequestInterceptor,
            multi: true
        },
        DatePipe,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoginInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: UrlInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorInterceptor,
        multi: true
      },
    ],
  exports: [


  ],
    bootstrap: [AppComponent]
})
export class AppModule { }
